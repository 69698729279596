import React, { useState } from "react";
import {
  PlayCircle,
  Smartphone,
  Settings,
  Clock,
  Calendar,
  ChevronRight,
  CheckCircle2,
  XCircle,
  Folder,
  MoreVertical,
  File,
  ArrowLeft,
  History,
  RefreshCw
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const TestPlanDetails = () => {
  const [isRunning, setIsRunning] = useState(false);

  const testPlan = {
    id: 1,
    name: "Regression Test v1.0",
    status: "ready",
    lastRun: "2024-12-10",
    device: {
      type: "emulator",
      farm: "Browserstack",
      model: "Samsung S24",
      os: "Android 14",
      permissions: ["Location", "Notifications", "Storage", "Camera"]
    },
    selectedTests: [
      {
        path: "/Authentication/Login Tests",
        tests: ["valid_login.test", "invalid_credentials.test"]
      },
      {
        path: "/Payment",
        tests: ["card_payment.test"]
      }
    ],
    history: [
      {
        date: "2024-12-10",
        status: "passed",
        duration: "45m",
        passed: 12,
        failed: 0
      },
      {
        date: "2024-12-09",
        status: "failed",
        duration: "42m",
        passed: 10,
        failed: 2
      }
    ]
  };

  const handleExecute = () => {
    setIsRunning(true);
    // Add execution logic
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center gap-4 mb-4">
            <Button variant="secondary" size="icon">
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <div className="flex-1">
              <div className="flex items-center gap-2">
                <h1 className="text-3xl font-bold text-foreground">
                  {testPlan.name}
                </h1>
                <Badge variant="outline">{testPlan.status}</Badge>
              </div>
              <div className="flex items-center gap-2 mt-2 text-muted-foreground">
                <Calendar className="h-4 w-4" />
                <span>Last run: {testPlan.lastRun}</span>
              </div>
            </div>
            <div className="flex gap-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="secondary">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem>Edit Plan</DropdownMenuItem>
                  <DropdownMenuItem>Duplicate Plan</DropdownMenuItem>
                  <DropdownMenuItem className="text-destructive">
                    Delete Plan
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                className="gap-2"
                onClick={handleExecute}
                disabled={isRunning}
              >
                {isRunning ? (
                  <>
                    <RefreshCw className="h-4 w-4 animate-spin" />
                    Running...
                  </>
                ) : (
                  <>
                    <PlayCircle className="h-4 w-4" />
                    Execute Tests
                  </>
                )}
              </Button>
            </div>
          </div>

          {/* Tabs */}
          <Tabs defaultValue="overview">
            <TabsList>
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="execution">Execution</TabsTrigger>
              <TabsTrigger value="history">History</TabsTrigger>
            </TabsList>

            <TabsContent value="overview" className="space-y-6 mt-6">
              {/* Device Configuration */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Smartphone className="h-5 w-5" />
                    Device Configuration
                  </CardTitle>
                </CardHeader>
                <CardContent className="grid md:grid-cols-2 gap-8">
                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium mb-2">Device Details</h3>
                      <div className="space-y-2 text-sm">
                        <div className="flex justify-between">
                          <span className="text-muted-foreground">Type:</span>
                          <span>{testPlan.device.type}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-muted-foreground">Farm:</span>
                          <span>{testPlan.device.farm}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-muted-foreground">Model:</span>
                          <span>{testPlan.device.model}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-muted-foreground">OS:</span>
                          <span>{testPlan.device.os}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <h3 className="font-medium mb-2">Permissions</h3>
                      <div className="grid grid-cols-2 gap-2">
                        {testPlan.device.permissions.map((permission) => (
                          <div
                            key={permission}
                            className="flex items-center gap-2 text-sm"
                          >
                            <CheckCircle2 className="h-4 w-4 text-green-500" />
                            {permission}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Selected Tests */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Folder className="h-5 w-5" />
                    Selected Tests
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    {testPlan.selectedTests.map((folder) => (
                      <div key={folder.path} className="space-y-2">
                        <div className="flex items-center gap-2 text-sm font-medium">
                          <Folder className="h-4 w-4 text-blue-500" />
                          {folder.path}
                        </div>
                        <div className="ml-6 space-y-1">
                          {folder.tests.map((test) => (
                            <div
                              key={test}
                              className="flex items-center gap-2 text-sm text-muted-foreground"
                            >
                              <File className="h-4 w-4 text-blue-400" />
                              {test}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="execution">
              {/* Add execution status and logs here */}
            </TabsContent>

            <TabsContent value="history" className="space-y-4 mt-6">
              {testPlan.history.map((run) => (
                <Card key={run.date}>
                  <CardContent className="flex items-center justify-between py-4">
                    <div className="flex items-center gap-4">
                      {run.status === "passed" ? (
                        <CheckCircle2 className="h-5 w-5 text-green-500" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500" />
                      )}
                      <div>
                        <div className="font-medium">{run.date}</div>
                        <div className="text-sm text-muted-foreground">
                          Duration: {run.duration}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="text-sm">
                        <span className="text-green-500">
                          {run.passed} passed
                        </span>
                        {run.failed > 0 && (
                          <span className="text-red-500 ml-2">
                            {run.failed} failed
                          </span>
                        )}
                      </div>
                      <Button variant="outline" size="sm">
                        View Report
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TestPlanDetails;
