import React, { useEffect, useRef } from "react";
import {
  ChevronLeft,
  ChevronRight,
  FileText,
  Settings,
  Variable
} from "lucide-react";
import { ApiList } from "@/components/features/api-management/ApiList";
import { ApiForm } from "@/components/features/api-management/ApiForm";
import { GlobalVariablesManager } from "@/components/features/api-management/GlobalVariablesManager";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { ApiItem } from "@/types";
import { useAppDispatch } from "@/store/hooks";
import { setSidebarExpanded, setActiveTab } from "@/store/features/apiSlice";

const SIDEBAR_TABS = {
  LIST: "list",
  ADD: "add",
  EDIT: "edit",
  VARIABLES: "variables"
} as const;

type TabType = (typeof SIDEBAR_TABS)[keyof typeof SIDEBAR_TABS];

export const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [activeTab, setActiveTabState] = React.useState<TabType | null>(null);
  const [selectedApi, setSelectedApi] = React.useState<ApiItem | null>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      const sidebar = sidebarRef.current;
      const target = event.target as Element;

      if (
        target.closest('[role="combobox"]') ||
        target.closest('[role="listbox"]') ||
        target.closest('[role="option"]')
      ) {
        return;
      }

      if (sidebar && !sidebar.contains(target) && isExpanded) {
        setIsExpanded(false);
        setActiveTabState(null);
        setSelectedApi(null);
        dispatch(setSidebarExpanded(false));
        dispatch(setActiveTab(null));
      }
    },
    [isExpanded, dispatch]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const handleTabClick = (tab: TabType) => {
    if (activeTab === tab) {
      setActiveTabState(null);
      setIsExpanded(false);
      setSelectedApi(null);
      dispatch(setSidebarExpanded(false));
      dispatch(setActiveTab(null));
    } else {
      setActiveTabState(tab);
      setIsExpanded(true);
      dispatch(setSidebarExpanded(true));
      dispatch(setActiveTab(tab));
      if (tab === SIDEBAR_TABS.LIST) {
        setSelectedApi(null);
      }
    }
  };

  const handleApiSelect = (api: ApiItem | null) => {
    setSelectedApi(api);
    if (api) {
      setActiveTabState(SIDEBAR_TABS.EDIT);
      dispatch(setActiveTab("edit"));
    }
  };

  const handleAddNewApi = () => {
    setActiveTabState(SIDEBAR_TABS.ADD);
    setIsExpanded(true);
    dispatch(setSidebarExpanded(true));
    dispatch(setActiveTab("add"));
  };

  const handleFormComplete = () => {
    setActiveTabState(SIDEBAR_TABS.LIST);
    setSelectedApi(null);
    dispatch(setActiveTab("list"));
  };

  return (
    <div
      ref={sidebarRef}
      id="sidebar"
      className={`fixed left-0 h-[calc(100vh-65px)] transition-all duration-300 z-10 flex top-[65px]`}
      style={{
        width: isExpanded ? "600px" : "56px"
      }}
    >
      {/* Navigation */}
      <div className="flex flex-col h-full w-14 bg-background border-r">
        <div className="flex flex-col gap-2 p-2 mt-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={
                    activeTab === SIDEBAR_TABS.LIST ? "default" : "ghost"
                  }
                  size="icon"
                  onClick={() => handleTabClick(SIDEBAR_TABS.LIST)}
                >
                  <FileText className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              {!isExpanded && (
                <TooltipContent side="right">
                  <p>API List</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
          {/* <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={
                    activeTab === SIDEBAR_TABS.VARIABLES ? "default" : "ghost"
                  }
                  size="icon"
                  onClick={() => handleTabClick(SIDEBAR_TABS.VARIABLES)}
                >
                  <Variable className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              {!isExpanded && (
                <TooltipContent side="right">
                  <p>Global Variables</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider> */}
        </div>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="mt-auto mb-4 mx-auto"
                onClick={() => {
                  const newExpanded = !isExpanded;
                  setIsExpanded(newExpanded);
                  dispatch(setSidebarExpanded(newExpanded));
                  if (!newExpanded) {
                    setActiveTabState(null);
                    setSelectedApi(null);
                    dispatch(setActiveTab(null));
                  }
                }}
              >
                {isExpanded ? (
                  <ChevronLeft className="h-5 w-5" />
                ) : (
                  <ChevronRight className="h-5 w-5" />
                )}
              </Button>
            </TooltipTrigger>
            {!isExpanded && (
              <TooltipContent side="right">
                <p>Expand sidebar</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>

      {/* Content */}
      <div
        className={`h-full overflow-hidden transition-all duration-300 ${
          isExpanded ? "w-[calc(100%-56px)]" : "w-0"
        }`}
      >
        <div className="w-full h-full bg-background/95 backdrop-blur-sm">
          <ScrollArea className="h-full">
            <div className="py-6 px-8">
              <div className="flex items-center gap-2">
                {activeTab === SIDEBAR_TABS.LIST && (
                  <FileText className="h-5 w-5 text-primary" />
                )}
                {(activeTab === SIDEBAR_TABS.ADD ||
                  activeTab === SIDEBAR_TABS.EDIT) && (
                  <Settings className="h-5 w-5 text-primary" />
                )}
                {activeTab === SIDEBAR_TABS.VARIABLES && (
                  <Variable className="h-5 w-5 text-primary" />
                )}
                <h2 className="text-foreground font-medium text-lg">
                  {activeTab === SIDEBAR_TABS.LIST
                    ? "API List"
                    : activeTab === SIDEBAR_TABS.EDIT
                    ? "Edit API"
                    : activeTab === SIDEBAR_TABS.VARIABLES
                    ? "Global Variables"
                    : "Create API"}
                </h2>
              </div>
              <Separator className="my-4" />
              <div className="px-1">
                {activeTab === SIDEBAR_TABS.LIST ? (
                  <ApiList
                    onSelect={handleApiSelect}
                    onAddNew={handleAddNewApi}
                  />
                ) : activeTab === SIDEBAR_TABS.ADD ||
                  activeTab === SIDEBAR_TABS.EDIT ? (
                  <ApiForm
                    initialData={selectedApi}
                    onSaved={handleFormComplete}
                  />
                ) : activeTab === SIDEBAR_TABS.VARIABLES ? (
                  <GlobalVariablesManager />
                ) : null}
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};
