import { useState, useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import { API_BASE_URL, SERVICE_URL } from "@/config";
import { useLogger } from "@/hooks/useLogger";
import { ApiItem, TestState } from "@/types";
import { retryFetchOperation } from "@/lib/retryFetchOperation";

interface StreamCallbacks {
  onLogUpdate: (chunk: string) => void;
  onInstanceFound: (instanceUuid: string, url: string) => void;
}

// Utility functions
const cleanStepText = (stepText: string): string => {
  return stepText.replace(/\s+/g, " ").trim();
};

export const useTestExecution = () => {
  const [testState, setTestState] = useState<TestState>({
    instanceId: null,
    stopThreadUrl: null,
    scriptSteps: [],
    isRunning: false,
    canStop: false
  });

  const { addLog, logError } = useLogger();
  const currentStreamController = useRef<AbortController | null>(null);
  const instanceStreams = useRef(new Map());

  const parseLog = useCallback((log: string) => {
    const logLines = log.split("\n");

    logLines.forEach((line) => {
      const cleanLog = line.replace(/[\x1B\x9B][[?;0-9]*[a-zA-Z]/g, "").trim();
      console.log("Parsing line:", cleanLog);

      if (cleanLog.includes("[[STEP]]")) {
        const command = cleanLog.split("[[STEP]] ")[1]?.trim();
        if (command) {
          const cleanedCommand = cleanStepText(command);
          console.log("Processing step command:", cleanedCommand);

          setTestState((prev) => {
            // Find the matching step
            const stepIndex = prev.scriptSteps.findIndex((step) => {
              const stepText = cleanStepText(step.text);
              const isMatch = stepText
                .toLowerCase()
                .includes(cleanedCommand.toLowerCase());
              if (isMatch) {
                console.log("Found matching step:", stepText);
              }
              return isMatch;
            });

            if (stepIndex > -1) {
              console.log("Updating step status to yellow:", stepIndex);
              const updatedSteps = [...prev.scriptSteps];
              updatedSteps[stepIndex] = {
                ...updatedSteps[stepIndex],
                status: "yellow"
              };
              return {
                ...prev,
                scriptSteps: updatedSteps
              };
            }
            return prev;
          });
        }
      } else if (cleanLog.includes("[[STEP_STATUS]]")) {
        const statusParts = cleanLog.split("[[STEP_STATUS]] ")[1]?.split(": ");
        const stepStatus = statusParts?.[0]?.trim();
        const command = statusParts?.[1]?.trim();

        if (command) {
          const cleanedCommand = cleanStepText(command);
          console.log(
            "Processing step status:",
            stepStatus,
            "for command:",
            cleanedCommand
          );

          setTestState((prev) => {
            const stepIndex = prev.scriptSteps.findIndex((step) => {
              const stepText = cleanStepText(step.text);
              const isMatch = stepText
                .toLowerCase()
                .includes(cleanedCommand.toLowerCase());
              if (isMatch) {
                console.log("Found matching step for status:", stepText);
              }
              return isMatch;
            });

            if (stepIndex > -1) {
              const color = stepStatus === "STEP_SUCCESS" ? "green" : "red";
              console.log(`Updating step status to ${color}:`, stepIndex);
              const updatedSteps = [...prev.scriptSteps];
              updatedSteps[stepIndex] = {
                ...updatedSteps[stepIndex],
                status: color
              };
              return {
                ...prev,
                scriptSteps: updatedSteps
              };
            }
            return prev;
          });
        }
      }
    });
  }, []);

  const parseTestSteps = useCallback((content: string) => {
    if (!content) return;

    const steps = content
      .split("\n")
      .map((step, index) => ({
        text: step,
        status: "grey" as const,
        id: index,
        isEmpty: step.trim() === ""
      }))
      .filter((step) => !step.isEmpty);

    setTestState((prev) => ({
      ...prev,
      scriptSteps: steps
    }));
  }, []);

  const streamStatus = useCallback(
    async (
      statusUrl: string,
      callbacks: StreamCallbacks,
      controller: AbortController
    ) => {
      const instanceName = statusUrl.split("/").pop() as string;
      let noDataRetryCount = 0;
      const MAX_NO_DATA_RETRIES = 5;

      if (instanceStreams.current.has(instanceName)) {
        const oldController = instanceStreams.current.get(instanceName);
        oldController.abort();
        instanceStreams.current.delete(instanceName);
      }

      instanceStreams.current.set(instanceName, controller);
      setTestState((prev) => ({ ...prev, isRunning: true, canStop: false }));

      const processLine = async (line: string): Promise<boolean> => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return false;

        callbacks.onLogUpdate(trimmedLine + "\n");

        if (trimmedLine.includes("No more data!")) {
          if (noDataRetryCount >= MAX_NO_DATA_RETRIES) {
            callbacks.onLogUpdate(trimmedLine + "\n");
            setTestState((prev) => ({
              ...prev,
              isRunning: true,
              canStop: false
            }));
          }
          return false;
        }

        parseLog(trimmedLine);

        const uuidMatch = trimmedLine.match(/instance_uuid:\s*([a-f0-9-]+)/i);
        const webrtcUrlMatch = trimmedLine.match(
          /webrtc_url:\s*(wss:\/\/[^\s]+)/i
        );

        if (uuidMatch && webrtcUrlMatch) {
          callbacks.onInstanceFound(uuidMatch[1], webrtcUrlMatch[1]);
        }

        const stopUrlMatch = trimmedLine.match(
          /stop_thread_command:\scurl\s(https:\/\/[^\s,"]+)/
        );
        if (stopUrlMatch) {
          setTestState((prev) => ({
            ...prev,
            stopThreadUrl: stopUrlMatch[1],
            canStop: true,
            isRunning: true
          }));
        }

        if (
          trimmedLine.includes("TEST_COMPLETED") ||
          trimmedLine.includes("THREAD_COMPLETED")
        ) {
          setTestState((prev) => ({
            ...prev,
            isRunning: false,
            canStop: false
          }));
          return true;
        }

        return false;
      };

      const startStream = async () => {
        let buffer = "";

        while (noDataRetryCount < MAX_NO_DATA_RETRIES) {
          const response = await retryFetchOperation(
            () =>
              fetch(statusUrl, {
                method: "GET",
                headers: {
                  "ngrok-skip-browser-warning": "1",
                  Accept: "text/event-stream",
                  Connection: "keep-alive",
                  "Cache-Control": "no-cache"
                },
                signal: controller.signal
              }),
            {
              onRetry: (attempt) => {
                callbacks.onLogUpdate(
                  `Retrying connection (attempt ${attempt})...\n`
                );
              }
            }
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch status stream: ${response.status}`
            );
          }

          const reader = response.body?.getReader();
          const decoder = new TextDecoder();

          try {
            while (true) {
              const { done, value } = await reader!.read();
              if (done) break;

              buffer += decoder.decode(value, { stream: true });
              const lines = buffer.split("\n");
              buffer = lines.pop() || "";

              for (const line of lines) {
                if (!line.trim()) continue;

                if (line.includes("No more data!")) {
                  noDataRetryCount++;
                  if (noDataRetryCount < MAX_NO_DATA_RETRIES) {
                    callbacks.onLogUpdate(
                      "No more data detected, waiting before retry...\n"
                    );
                    await new Promise((resolve) => setTimeout(resolve, 5000));
                    break;
                  }
                }

                const shouldStop = await processLine(line);
                if (shouldStop) return;
              }
            }
          } finally {
            reader?.releaseLock();
          }
        }
      };

      try {
        await startStream();
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          Sentry.captureException(error);
          logError(error.message);
        }
      } finally {
        instanceStreams.current.delete(instanceName);
        setTestState((prev) => ({ ...prev, isRunning: false, canStop: false }));
      }
    },
    [logError, parseLog]
  );

  const startTest = useCallback(
    async (
      instanceName: string,
      appName: string,
      testFile: File,
      saveInstance: boolean,
      apiFiles?: File[]
    ) => {
      const formData = new FormData();
      formData.append("instance_name", instanceName);
      formData.append("app_name", appName);
      formData.append("test_file", testFile);
      if (saveInstance) {
        formData.append("save_instance", "True");
      }

      // Handle API files if provided
      if (apiFiles && apiFiles.length > 0) {
        apiFiles.forEach((file) => {
          formData.append("api_files", file);
        });
      }

      // Debug logging
      console.log("FormData contents:");
      for (const [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log(`${key}: ${value.name} (${value.size} bytes)`);
          if (value.name.startsWith("api_")) {
            const reader = new FileReader();
            reader.onload = () => {
              console.log(
                `Content of ${value.name}:`,
                JSON.parse(reader.result as string)
              );
            };
            reader.readAsText(value);
          }
        } else {
          console.log(`${key}: ${value}`);
        }
      }

      try {
        const response = await retryFetchOperation(
          () =>
            fetch(`${API_BASE_URL}/test/`, {
              method: "POST",
              body: formData
            }),
          {
            onRetry: (attempt) => {
              addLog(`Retrying test start (attempt ${attempt})...`, "info");
            }
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Failed to start the test");
        }
        const data = await response.json();
        setTestState((prev) => ({ ...prev, isRunning: true }));
        return data;
      } catch (error) {
        if (error instanceof Error) {
          Sentry.captureException(error);
          logError(error.message);
        }
        throw error;
      }
    },
    [logError]
  );

  const stopTest = useCallback(async () => {
    if (!testState.stopThreadUrl) {
      addLog("Stop URL not available. Cannot stop the test.", "error");
      return;
    }

    const confirmed = window.confirm(
      "Test will be stopped once the AI performs the existing step."
    );

    if (!confirmed) return;

    try {
      const STOP_URL = `${SERVICE_URL}/stop_thread/${testState.stopThreadUrl.substring(
        testState.stopThreadUrl.lastIndexOf("/") + 1
      )}`;

      await fetch(STOP_URL, {
        method: "GET",
        headers: { "ngrok-skip-browser-warning": "1" }
      });

      addLog("Test stopped successfully.", "info");
      alert("Test has been stopped successfully.");

      setTestState((prev) => ({
        ...prev,
        stopThreadUrl: null,
        isRunning: false
      }));

      if (currentStreamController.current) {
        currentStreamController.current.abort();
        currentStreamController.current = null;
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error instanceof Error) {
        addLog(`Failed to stop test: ${error.message}`, "error");
      }
    }
  }, [testState.stopThreadUrl, addLog]);

  const clearState = useCallback(async () => {
    try {
      // Stop disposable instance if it exists
      if (testState.instanceId) {
        const requestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-token": import.meta.env.VITE_API_TOKEN
          }
        };

        try {
          const response = await fetch(
            `https://api.geny.io/cloud/v1/instances/${testState.instanceId}/stop-disposable`,
            requestInit
          );
          const data = await response.json();
          console.log("Instance stop response:", data);
        } catch (error) {
          console.error("Error stopping instance:", error);
        }
      }

      // Abort any running streams
      if (currentStreamController.current) {
        currentStreamController.current.abort();
        currentStreamController.current = null;
      }

      // Clear all existing streams
      for (const controller of instanceStreams.current.values()) {
        controller.abort();
      }
      instanceStreams.current.clear();

      // Reset all state
      setTestState({
        instanceId: null,
        stopThreadUrl: null,
        scriptSteps: [],
        isRunning: false,
        canStop: false
      });

      // Add initial log message
      addLog("Logs will appear here...", "info");

      // Reset player iframe if it exists
      const playerFrame = document.querySelector("iframe");
      if (playerFrame) {
        playerFrame.src = "about:blank";
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error during reset:", error);
    }
  }, [testState.instanceId, addLog]);

  return {
    testState,
    parseTestSteps,
    startTest,
    streamStatus,
    stopTest,
    clearState,
    currentStreamController
  };
};
