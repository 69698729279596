import React from "react";

interface PlayerProps {
  url?: string;
}

export const Player: React.FC<PlayerProps> = ({ url = "about:blank" }) => {
  return (
    <div className="w-[30%] h-[calc(100vh-4rem)] md:h-full relative overflow-hidden">
      <style>
        {`
        
        
          @media (min-height: 402px) and (max-height: 700px) {
            #player-frame {
            width:100%;
            height:100vh;
              transform: scale(0.65) !important;
            }
          }
        `}
      </style>
      <iframe
        id="player-frame"
        src={url}
        frameBorder="0"
        sandbox="allow-scripts allow-same-origin allow-forms"
        className="
          absolute top-0 left-0 
          w-full h-full 
          border-none bg-transparent 
          origin-center
          scale-90
          min-h-[320px]
        "
      />
      <div className="absolute bottom-0 left-0 w-full h-12 flex items-center justify-center z-10 bg-transparent" />
    </div>
  );
};
