import React from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  setConfig,
  setShowEditor,
  selectIsLoading,
  setIsLoading
} from "@/store/features/editorSlice";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2, AlertTriangle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { TestConfig } from "@/types";
import Header from "@/components/layout/Header";
import { API_BASE_URL } from "@/config";

interface EmulatorStatus {
  instance_name: string;
  emulator_status: string;
  test_status: string;
  provider: string | null;
  metadata: any | null;
}

export const StartPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectIsLoading);
  const [error, setError] = React.useState<string>("");
  const [status, setStatus] = React.useState<EmulatorStatus | null>(null);
  const [existingInstance, setExistingInstance] =
    React.useState<boolean>(false);
  const [modalError, setModalError] = React.useState<string>("");

  const [config, setLocalConfig] = React.useState<TestConfig>({
    instanceName: "",
    appName: "",
    saveInstance: false
  });

  const checkHealth = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/health`, {
        headers: {
          "ngrok-skip-browser-warning": "1"
        }
      });
      return response.ok;
    } catch {
      return false;
    }
  };

  // const isHealthy = await checkHealth();
  // if (!isHealthy) {
  //   setError(
  //     "Server is currently unavailable. Please try again later or contact support if the issue persists."
  //   );
  //   return;
  // }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setStatus(null);
    setExistingInstance(false);
    dispatch(setIsLoading(true));

    try {
      const statusResponse = await fetch(
        `${API_BASE_URL}/emulator-status/?instance_name=${config.instanceName}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "1"
          }
        }
      );

      const status = null;
      const data = await statusResponse.json();

      if (
        statusResponse.status === 404 ||
        (data && data.test_status === "waiting")
      ) {
        dispatch(setConfig(config));
        dispatch(setShowEditor(true));
        navigate("/dashboard");
      } else {
        // Check if response contains error detail
        if (data.detail) {
          setError(
            `${data.detail} Please try using a different instance name.`
          );
        } else {
          // Handle normal status response
          setStatus(data);
          setExistingInstance(true);
        }
      }
    } catch (error) {
      console.error("Error during initialization:", error);
      setError(
        "Unable to connect to the server. Please check your connection and try again."
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleConfigUpdate = (
    field: keyof TestConfig,
    value: string | boolean
  ) => {
    setLocalConfig((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleInstanceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setStatus(null);
    setExistingInstance(false);
    handleConfigUpdate("instanceName", e.target.value);
  };

  const hasRequiredFields = config.instanceName && config.appName;
  const isDisabled = isLoading;

  return (
    <div className="min-h-screen flex flex-col bg-black">
      <Header />
      <div className="flex-1 flex items-center justify-center p-4">
        <Card className="w-full max-w-md bg-gray-900 border-gray-800">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center text-white">
              Configuration
            </CardTitle>
          </CardHeader>

          <form onSubmit={handleSubmit}>
            <CardContent className="space-y-6">
              {error && (
                <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded">
                  {error}
                </div>
              )}

              {existingInstance && (
                <Alert className="bg-yellow-500/10 border-yellow-500/50">
                  <AlertTriangle className="h-4 w-4 text-yellow-500" />
                  <AlertDescription className="text-yellow-500">
                    An emulator instance with this name already exists. Current
                    status:
                  </AlertDescription>
                </Alert>
              )}

              {status && (
                <div className="bg-blue-500/10 border border-blue-500 text-white p-4 rounded space-y-2">
                  <div className="grid grid-cols-2 gap-2">
                    <Label className="text-gray-400">Instance Name:</Label>
                    <span>{status.instance_name}</span>

                    <Label className="text-gray-400">Emulator Status:</Label>
                    <span
                      className={
                        status.emulator_status === "on"
                          ? "text-green-500"
                          : "text-yellow-500"
                      }
                    >
                      {status.emulator_status}
                    </span>

                    <Label className="text-gray-400">Test Status:</Label>
                    <span
                      className={
                        status.test_status === "waiting"
                          ? "text-yellow-500"
                          : status.test_status === "completed"
                          ? "text-green-500"
                          : "text-white"
                      }
                    >
                      {status.test_status}
                    </span>

                    {status.provider && (
                      <>
                        <Label className="text-gray-400">Provider:</Label>
                        <span>{status.provider}</span>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <Label htmlFor="instanceName" className="text-gray-200">
                  Instance Name
                </Label>
                <Input
                  id="instanceName"
                  placeholder="Enter instance name"
                  value={config.instanceName}
                  onChange={handleInstanceNameChange}
                  disabled={isDisabled}
                  className="w-full bg-gray-800 border-gray-700 text-white placeholder-gray-400"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="appName" className="text-gray-200">
                  App Name
                </Label>
                <Input
                  id="appName"
                  placeholder="Enter app name"
                  value={config.appName}
                  onChange={(e) =>
                    handleConfigUpdate("appName", e.target.value)
                  }
                  disabled={isDisabled}
                  className="w-full bg-gray-800 border-gray-700 text-white placeholder-gray-400"
                  required
                />
              </div>
            </CardContent>

            <CardFooter>
              <Button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                disabled={!hasRequiredFields || isDisabled}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default StartPage;
