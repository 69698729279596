import { useAuth0 } from "@auth0/auth0-react";
import { LogOut, User, Building2, Terminal } from "lucide-react";
import { Button } from "@/components/ui/button";

const Header = () => {
  const { logout, user } = useAuth0();
  const storedCompanyName = user?.company_name;

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className=" mx-auto flex h-14 items-center justify-between px-6">
        <Button variant="ghost" className="space-x-2 px-2 hover:bg-transparent">
          <Terminal className="h-5 w-5 text-primary" />
          <div className="flex flex-col items-start">
            <span className="text-lg font-semibold text-foreground">Drizz</span>
            <span className="text-xs text-primary -mt-1">Playground</span>
          </div>
        </Button>

        {/* User info and actions */}
        {user && (
          <div className="flex items-center gap-4">
            <Button variant="secondary" size="sm" className="gap-2">
              <User className="h-4 w-4 text-primary" />
              <span>{user.name || user.email}</span>
            </Button>

            <Button variant="secondary" size="sm" className="gap-2">
              <Building2 className="h-4 w-4 text-primary" />
              <span className="capitalize">{storedCompanyName}</span>
            </Button>

            <Button
              variant="secondary"
              size="sm"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              className="gap-2 bg-secondary hover:bg-secondary/80"
            >
              <LogOut className="h-4 w-4" />
              <span>Logout</span>
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
