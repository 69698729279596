import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { StandardMonacoEditor } from "./StandardMonacoEditor";
import { LineMonacoEditor } from "./LineMonacoEditor";
import { LineEditorRef, MonacoEditorProps, MonacoEditorRef } from "@/types";

export const MonacoEditor = forwardRef<MonacoEditorRef, MonacoEditorProps>(
  (
    {
      value,
      onChange,
      height = "500px",
      language = "plaintext",
      theme = "vs-dark",
      isRunning = false,
      isSingleLineMode = false,
      onLineExecute,
      executedLines = new Set(),
      onReorder
    },
    ref
  ) => {
    const standardEditorRef = useRef<MonacoEditorRef>(null);
    const lineEditorRef = useRef<LineEditorRef>(null);

    useImperativeHandle(ref, () => ({
      getValue: () =>
        isSingleLineMode
          ? lineEditorRef.current?.getValue() ?? ""
          : standardEditorRef.current?.getValue() ?? "",
      setValue: (value: string) => {
        if (isSingleLineMode) {
          lineEditorRef.current?.setValue(value);
        } else {
          standardEditorRef.current?.setValue(value);
        }
      },
      editor: isSingleLineMode
        ? lineEditorRef.current?.editor
        : standardEditorRef.current?.editor,
      clearExecutedLines: () => {
        if (isSingleLineMode) {
          lineEditorRef.current?.clearExecutedLines?.();
        }
      }
    }));

    return (
      <>
        {isSingleLineMode ? (
          <LineMonacoEditor
            ref={lineEditorRef}
            value={value}
            onChange={onChange}
            height={height}
            language={language}
            theme={theme}
            onLineExecute={onLineExecute}
            executedLines={executedLines}
            onReorder={onReorder}
          />
        ) : (
          <StandardMonacoEditor
            ref={standardEditorRef}
            value={value}
            onChange={onChange}
            height={height}
            language={language}
            theme={theme}
            isRunning={isRunning}
          />
        )}
      </>
    );
  }
);

MonacoEditor.displayName = "MonacoEditor";

export default MonacoEditor;
