import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/store/hooks";
import { selectConfig } from "@/store/features/editorSlice";

export const useConfigCheck = () => {
  const navigate = useNavigate();
  const config = useAppSelector(selectConfig);

  useEffect(() => {
    if (!config.instanceName || !config.appName) {
      navigate("/start");
    }
  }, [config, navigate]);

  return {
    hasValidConfig: !!(config.instanceName && config.appName),
    config
  };
};
