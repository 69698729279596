import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import {
  ApiFormData,
  ApiItem,
  ApiResponse,
  ApiState,
  GlobalVariable,
  TabType
} from "@/types";
import {
  loadApisFromStorage,
  loadVariablesFromStorage,
  saveVariablesToStorage,
  syncApisToStorage
} from "@/utils/storage";

const initialState: ApiState = {
  apis: loadApisFromStorage(),
  selectedApi: null,
  executingApiId: null,
  isLoading: false,
  error: null,
  sidebarExpanded: false,
  activeTab: null,
  variables: loadVariablesFromStorage()
};

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    setApis: (state, action: PayloadAction<ApiItem[]>) => {
      state.apis = action.payload;
      syncApisToStorage(state.apis);
    },
    addApi: (state, action: PayloadAction<ApiFormData>) => {
      const timestamp = new Date().toISOString();
      const newApi: ApiItem = {
        id: Date.now().toString(),
        created_at: timestamp,
        updated_at: timestamp,
        ...action.payload
      };
      state.apis.push(newApi);
      syncApisToStorage(state.apis);
    },
    updateApi: (
      state,
      action: PayloadAction<{ id: string; data: ApiFormData }>
    ) => {
      const timestamp = new Date().toISOString();
      state.apis = state.apis.map((api) =>
        api.id === action.payload.id
          ? {
              ...api,
              ...action.payload.data,
              responses: api.responses,
              updated_at: timestamp
            }
          : api
      );
      syncApisToStorage(state.apis);
    },
    deleteApi: (state, action: PayloadAction<string>) => {
      state.apis = state.apis.filter((api) => api.name !== action.payload);
      if (state.selectedApi?.name === action.payload) {
        state.selectedApi = null;
      }
      syncApisToStorage(state.apis);
    },
    // Add this to your reducers in apiSlice
    saveResponse: (
      state,
      action: PayloadAction<{ id: string; response: ApiResponse }>
    ) => {
      state.apis = state.apis.map((api) => {
        if (api.id === action.payload.id) {
          return {
            ...api,
            responses: action.payload.response
          };
        }
        return api;
      });
      syncApisToStorage(state.apis);
    },
    setSelectedApi: (state, action: PayloadAction<ApiItem | null>) => {
      state.selectedApi = action.payload;
    },
    setExecutingApiId: (state, action: PayloadAction<string | null>) => {
      state.executingApiId = action.payload;
    },
    setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
      state.sidebarExpanded = action.payload;
    },
    addVariable: (state, action: PayloadAction<Omit<GlobalVariable, "id">>) => {
      const newVariable = {
        id: Date.now().toString(),
        ...action.payload
      };
      state.variables.push(newVariable);
      saveVariablesToStorage(state.variables);
    },
    updateVariable: (
      state,
      action: PayloadAction<{ id: string; data: Partial<GlobalVariable> }>
    ) => {
      state.variables = state.variables.map((variable) =>
        variable.id === action.payload.id
          ? { ...variable, ...action.payload.data }
          : variable
      );
      saveVariablesToStorage(state.variables);
    },
    deleteVariable: (state, action: PayloadAction<string>) => {
      state.variables = state.variables.filter(
        (variable) => variable.id !== action.payload
      );
      saveVariablesToStorage(state.variables);
    },
    setActiveTab: (state, action: PayloadAction<TabType>) => {
      state.activeTab = action.payload;
    },
    resetApiState: (state) => {
      return initialState;
    }
  }
});

// Actions
export const {
  setApis,
  addApi,
  updateApi,
  deleteApi,
  setSelectedApi,
  saveResponse,
  setExecutingApiId,
  setSidebarExpanded,
  addVariable,
  updateVariable,
  deleteVariable,
  setActiveTab,
  resetApiState
} = apiSlice.actions;

// Thunk for loading APIs from localStorage
export const loadApisFromLocalStorage = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const savedApis = localStorage.getItem("savedApis");
      if (savedApis) {
        dispatch(setApis(JSON.parse(savedApis)));
      }
    } catch (error) {
      console.error("Failed to load APIs:", error);
    }
  };
};

export const executeApi = async (api: ApiItem) => {
  try {
    const response = await fetch("http://localhost:4000/api/test", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        method: api.method,
        url: api.url,
        headers: api.headers,
        body: api.body ? JSON.parse(api.body) : undefined
      })
    });

    return await response.json();
  } catch (error) {
    return {
      status: 500,
      statusText: "Internal Server Error",
      headers: {},
      data: null,
      error: {
        message:
          error instanceof Error ? error.message : "Unknown error occurred",
        stack: error instanceof Error ? error.stack : undefined
      }
    };
  }
};

// Selectors
export const selectApis = (state: RootState) => state.api.apis;
export const selectSelectedApi = (state: RootState) => state.api.selectedApi;
export const selectExecutingApiId = (state: RootState) =>
  state.api.executingApiId;
export const selectApiIsLoading = (state: RootState) => state.api.isLoading;
export const selectApiError = (state: RootState) => state.api.error;
export const selectSidebarExpanded = (state: RootState) =>
  state.api.sidebarExpanded;
export const selectActiveTab = (state: RootState) => state.api.activeTab;
export const selectGlobalVariables = (state: RootState) => state.api.variables;

export default apiSlice.reducer;
