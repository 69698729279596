import React, { useEffect, useCallback, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { EditorContainer } from "@/components/features/editor/EditorContainer";
import { Logger } from "@/components/features/logger/Logger";
import { Player } from "@/components/features/player/Player";
import { useTestExecution } from "@/hooks/useTestExecution";
import { useLogger } from "@/hooks/useLogger";
import { API_BASE_URL, SINGLE_TEST_URL } from "@/config";
import { constructPlayerUrl } from "@/utils/urlUtils";
import Header from "@/components/layout/Header";
import * as Sentry from "@sentry/react";
import "@/style/editor.css";
import {
  addExecutedLine,
  clearExecutedLines,
  resetEditor,
  selectConfig,
  selectExecutedLines,
  selectInstanceUUID,
  selectIsLoading,
  selectIsSingleLineMode,
  selectPlayerUrl,
  selectShowEditor,
  selectSingleModeLoading,
  setConfig,
  setInstanceUUID,
  setIsLoading,
  setIsSingleLineMode,
  setPlayerUrl,
  setShowEditor,
  setSingleModeLoading,
  updateSaveInstance
} from "@/store/features/editorSlice";
import { TestConfig, EditorContainerRef } from "@/types";
import { Sidebar } from "@/components/features/sidebar/Sidebar";
import { useConfigCheck } from "@/hooks/useConfigRedirect";
import EmulatorStatusModal from "@/components/layout/EmulatorStatusModal";
import { retryFetchOperation } from "@/lib/retryFetchOperation";
import { useAuth0 } from "@auth0/auth0-react";
import { selectApis, setApis } from "@/store/features/apiSlice";
import { ApiStorage } from "@/store/s3/api";

const getRelevantApiFiles = (apis: any[], testContent: string): File[] => {
  // Directly filter APIs based on whether their names appear in the test content
  const relevantApis = apis.filter((api) =>
    testContent.includes(`${api.name}`)
  );

  console.log(
    "Found matching APIs:",
    relevantApis.map((api) => api.name)
  );

  // Create files for matching APIs
  return relevantApis.map((api) => {
    const apiBlob = new Blob([JSON.stringify(api)], {
      type: "application/json"
    });
    return new File([apiBlob], `${api.name}`);
  });
};

export const Dashboard: React.FC = () => {
  const { logs, addLog, clearLogs, parseANSIColors } = useLogger();
  const {
    testState,
    parseTestSteps,
    startTest,
    streamStatus,
    stopTest,
    clearState,
    currentStreamController
  } = useTestExecution();

  const dispatch = useAppDispatch();
  const showEditor = useAppSelector(selectShowEditor);
  const isSingleLineMode = useAppSelector(selectIsSingleLineMode);
  const isLoading = useAppSelector(selectIsLoading);
  const config = useAppSelector(selectConfig);
  const playerUrl = useAppSelector(selectPlayerUrl);
  const executedLines = useAppSelector(selectExecutedLines);
  const singleModeLoading = useAppSelector(selectSingleModeLoading);
  const { hasValidConfig } = useConfigCheck();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [statusData, setStatusData] = React.useState<any>(null);
  const [modalError, setModalError] = React.useState<string>("");
  const instanceUUID = useAppSelector(selectInstanceUUID);

  const editorRef = useRef<EditorContainerRef>(null);
  const logsContainer = useRef<HTMLDivElement>(null);

  const apis = useAppSelector(selectApis);
  const { user } = useAuth0();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const apiStorage = new ApiStorage();

  useEffect(() => {
    currentStreamController.current = new AbortController();
    return () => {
      if (currentStreamController.current) {
        currentStreamController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (logsContainer.current) {
      logsContainer.current.scrollTop = logsContainer.current.scrollHeight;
    }
  }, [logs]);

  const loadApisFromS3 = async () => {
    if (!user?.company_name) return;

    setIsApiLoading(true);
    try {
      const s3Apis = await apiStorage.getApisByCompany(user.company_name);
      dispatch(setApis(s3Apis));
    } catch (error) {
      console.error("Failed to load APIs from S3:", error);
      addLog("Failed to load APIs", "error");
    } finally {
      setIsApiLoading(false);
    }
  };

  // Load APIs when component mounts
  useEffect(() => {
    loadApisFromS3();
  }, [user?.company_name]);

  const handleRun = async () => {
    if (!config) return;
    if (currentStreamController.current) {
      currentStreamController.current.abort();
    }
    currentStreamController.current = new AbortController();

    try {
      const editorContent = editorRef.current?.getValue() || "";
      if (!editorContent.trim()) {
        addLog("Please enter test content or upload a test file.", "error");
        return;
      }

      clearLogs();
      const blob = new Blob([editorContent], { type: "text/plain" });
      const testFile = new File([blob], "test-script", { type: "text/plain" });

      const statusResponse = await retryFetchOperation(() =>
        fetch(
          `${API_BASE_URL}/emulator-status/?instance_name=${config.instanceName}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "1"
            }
          }
        )
      );
      const status = null;
      const data = await statusResponse.json();

      if (
        statusResponse.status === 404 ||
        (data && data.test_status === "waiting")
      ) {
        const apiFiles = getRelevantApiFiles(apis, editorContent);
        console.log("apiFiles", apiFiles);
        // Log which APIs were found and will be used
        console.log(`Found ${apiFiles.length} relevant APIs for the test`);
        apiFiles.forEach((file) => {
          addLog(`Including API: ${file.name}`, "info");
        });

        const parameterBox = `
        <div class="outer-log-box">
          <p class="log-title">Starting Test With Following Parameters</p>
          <p><span class="parameter-label">Instance Name:</span><span class="parameter-value">${config.instanceName}</span></p>
          <p><span class="parameter-label">Package Name:</span><span class="parameter-value">${config.appName}</span></p>
          <p><span class="parameter-label">File Name:</span><span class="parameter-value">test-script</span></p>
          <p><span class="parameter-label">Save Instance:</span><span class="parameter-value">${config.saveInstance}</span></p>
        </div>
      `;
        addLog(parameterBox);

        console.log("config.saveInstance", config.saveInstance);
        const data = await startTest(
          config.instanceName,
          config.appName,
          testFile,
          config.saveInstance,
          apiFiles
        );

        if (data.status === "running") {
          const statusUrl = `${API_BASE_URL}/status/${config.instanceName}`;

          await streamStatus(
            statusUrl,
            {
              onLogUpdate: (chunk) => {
                const parsedChunk = parseANSIColors(chunk);
                addLog(parsedChunk);
              },
              onInstanceFound: async (instanceUuid, url) => {
                try {
                  dispatch(setInstanceUUID(instanceUuid));
                  const playerUrl = await constructPlayerUrl(instanceUuid, url);
                  dispatch(setPlayerUrl(playerUrl));
                } catch (error) {
                  console.error("JWT Token error:", error);
                }
              }
            },
            currentStreamController.current
          );
        } else {
          addLog("Error: Could not start test.", "error");
        }
      } else if (data.detail) {
        // Show error in modal
        setModalError(data.detail);
        setShowStatusModal(true);
      } else {
        // Show normal status modal
        setStatusData(data);
        setModalError("");
        setShowStatusModal(true);
      }
    } catch (error) {
      Sentry.captureException(new Error(error));
      if (error instanceof Error) {
        addLog(`${error.message}`, "error");
      }
    }
  };

  const handleStop = async () => {
    await stopTest();
  };

  const handleEditorChange = (value: string) => {
    parseTestSteps(value);
  };

  const handleLineReorder = useCallback(
    async (fromIndex: number, toIndex: number) => {
      if (!editorRef.current) return;

      const content = editorRef.current.getValue();
      const lines = content.split("\n");
      const [movedLine] = lines.splice(fromIndex, 1);
      lines.splice(toIndex, 0, movedLine);
      const newContent = lines.join("\n");

      // Update executed lines after reordering
      const updatedExecutedLines = new Set<number>();
      executedLines.forEach((line) => {
        if (line === fromIndex + 1) {
          updatedExecutedLines.add(toIndex + 1);
        } else if (line > fromIndex + 1 && line <= toIndex + 1) {
          updatedExecutedLines.add(line - 1);
        } else if (line < fromIndex + 1 && line >= toIndex + 1) {
          updatedExecutedLines.add(line + 1);
        } else {
          updatedExecutedLines.add(line);
        }
      });

      dispatch(clearExecutedLines());
      updatedExecutedLines.forEach((lineNumber) => {
        dispatch(addExecutedLine(lineNumber));
      });

      editorRef.current.setValue(newContent);
      handleEditorChange(newContent);
    },
    [executedLines, handleEditorChange]
  );

  const handleSaveInstanceChange = (checked: boolean) => {
    dispatch(updateSaveInstance(checked));
  };

  const initializeSingleLineMode = async (enabled: boolean) => {
    dispatch(setSingleModeLoading(true));
    if (enabled && config.instanceName && config.appName) {
      try {
        currentStreamController.current = new AbortController();
        console.log("Before process");
        addLog("Initializing single line mode...", "info");

        const response = await fetch(`${SINGLE_TEST_URL}/start_test`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            instance_name: config.instanceName,
            package_name: config.appName,
            client_ID: "client123"
          })
        });
        dispatch(setSingleModeLoading(false));
        const data = await response.json();
        console.log("data", data);
        if (data.status === "Test started") {
          dispatch(clearExecutedLines());
          dispatch(setIsSingleLineMode(true));

          // Log initial setup with required patterns that streamStatus expects
          const initialLogs = [
            `Starting emulator instance...`,
            `Found instance_uuid: ${data.emulator_id}`,
            `Found webrtc_url: ${data.webrtc_url}`,
            `Instance initialized successfully`
          ].join("\n");

          addLog(initialLogs);

          const parameterBox = `
          <div class="outer-log-box">
            <p class="log-title">Single Line Mode Initialized</p>
            <p><span class="parameter-label">Instance Name:</span><span class="parameter-value">${config.instanceName}</span></p>
            <p><span class="parameter-label">Package Name:</span><span class="parameter-value">${config.appName}</span></p>
            <p><span class="parameter-label">Emulator ID:</span><span class="parameter-value">${data.emulator_id}</span></p>
            <p><span class="parameter-label">WebRTC URL:</span><span class="parameter-value">${data.webrtc_url}</span></p>
          </div>
        `;
          addLog(parameterBox);

          try {
            const playerUrl = await constructPlayerUrl(
              data.emulator_id,
              data.webrtc_url
            );
            dispatch(setPlayerUrl(playerUrl));
            addLog("Player URL configured successfully", "info");

            // Start streaming with a slight delay to ensure instance is ready
            setTimeout(async () => {
              if (currentStreamController.current) {
                // Use the same URL pattern as handleRun
                const statusUrl = `${SINGLE_TEST_URL}/status/${config.instanceName}`;

                addLog("Establishing log stream connection...", "info");

                try {
                  await streamStatus(
                    statusUrl,
                    {
                      onLogUpdate: (chunk) => {
                        const parsedChunk = parseANSIColors(chunk);
                        console.log("parsedChunk", parsedChunk);
                        addLog(parsedChunk, "info");
                      },
                      onInstanceFound: async () => {}
                    },
                    currentStreamController.current
                  );
                } catch (error) {
                  if (error instanceof Error && error.name !== "AbortError") {
                    console.error("Stream error:", error);
                    addLog(`Stream error: ${error.message}`, "error");
                  }
                }
              }
            }, 2000);

            addLog(
              "Single line mode ready. You can now execute instructions line by line.",
              "info"
            );
          } catch (error) {
            console.error("Setup error:", error);
            addLog(
              "Setup completed with limited functionality. Your instance is still running.",
              "error"
            );
          }
        } else {
          dispatch(setSingleModeLoading(false));
          throw new Error("Failed to initialize single line mode");
        }
      } catch (error) {
        console.error("Error initializing single line mode:", error);
        dispatch(setSingleModeLoading(false));
        addLog(
          `Failed to initialize single line mode: ${
            error instanceof Error ? error.message : "Unknown error"
          }`,
          "error"
        );
        dispatch(setIsSingleLineMode(false));
      }
    } else {
      dispatch(clearExecutedLines());
      dispatch(setIsSingleLineMode(false));
      dispatch(setSingleModeLoading(false));
    }
  };

  const handleReset = async () => {
    const shouldClear = window.confirm(
      "Are you sure you want to reset everything?"
    );
    if (shouldClear) {
      try {
        // const requestInit = {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     "x-api-token": import.meta.env.VITE_API_TOKEN
        //   }
        // };

        // await fetch(
        //   `https://api.geny.io/cloud/v1/instances/${instanceUUID}/stop-disposable`,
        //   requestInit
        // );

        await clearState();
        clearLogs();
        dispatch(resetEditor());

        if (editorRef.current) {
          editorRef.current.setValue("");
          if (editorRef.current.clearExecutedLines) {
            editorRef.current.clearExecutedLines();
          }
        }

        localStorage.removeItem("editorContent");

        setTimeout(() => {
          addLog("Logs will appear here...", "info");
        }, 100);
      } catch (error) {
        console.error("Error during clear:", error);
      }
    }
  };

  const handleCloseInstance = async () => {
    try {
      // First abort any ongoing streams
      if (currentStreamController.current) {
        currentStreamController.current.abort();
      }

      // Show loading in logs
      addLog("Closing instance...", "info");

      const response = await fetch(`${SINGLE_TEST_URL}/stop_test`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          instance_name: config.instanceName,
          package_name: config.appName,
          client_ID: "client123"
        })
      });

      const data = await response.json();
      console.log("Data", data);
      handleReset();
    } catch (error) {
      console.error("Error closing instance:", error);
      addLog(
        `Failed to close instance: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  const handleDownloadCode = () => {
    if (!editorRef.current) return;

    const content = editorRef.current.getValue();
    if (!content) {
      addLog("No content to download", "error");
      return;
    }

    try {
      const element = document.createElement("a");
      const file = new Blob([content], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${config.instanceName || "code"}_${
        new Date().toISOString().split("T")[0]
      }.txt`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error downloading code:", error);
      addLog(
        `Failed to download code: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  const handleLineExecute = async (lineNumber: number, content: string) => {
    console.log("main handler");
    // if (isSingleLineMode) {
    try {
      console.log("Content: ", content);
      addLog(`Executing line ${lineNumber}: ${content}`, "info");
      dispatch(addExecutedLine(lineNumber));

      const response = await fetch(
        `${SINGLE_TEST_URL}/submit_instruction/${config.instanceName}_client123`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ instruction: content })
        }
      );

      const data = await response.json();
      console.log("API Response", data);
      if (data.status === "Instruction submitted") {
        addLog(`✓ Line ${lineNumber}: Instruction submitted successfully`);
        // Move line data here
        addLog(`Instruction: ${data.instruction}`, "info");
      } else {
        addLog(`✗ Line ${lineNumber}: Failed to submit instruction`, "error");
      }
    } catch (error) {
      console.error("Error executing line:", error);
      addLog(
        `Error executing line ${lineNumber}: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
    // }
  };

  if (!hasValidConfig) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col editor-body " id="editor-body">
      <Header />
      <div className="flex h-screen pl-16  w-full bg-[--bg-secondary]  ">
        <div className="left-section border-0">
          <div className="card" id="formCard">
            <>
              <div id="editorSection">
                <EditorContainer
                  canStop={testState.canStop}
                  ref={editorRef}
                  onEditorChange={handleEditorChange}
                  onRun={handleRun}
                  onStop={handleStop}
                  onReset={handleReset}
                  isRunning={testState.isRunning}
                  initialValue=""
                  config={config}
                  isSingleLineMode={isSingleLineMode}
                  onSingleLineModeChange={async (enabled: boolean) => {
                    dispatch(setIsSingleLineMode(enabled));
                    await initializeSingleLineMode(enabled);
                  }}
                  onSaveInstanceChange={handleSaveInstanceChange}
                  onLineExecute={handleLineExecute}
                  executedLines={new Set(executedLines)}
                  onReorder={handleLineReorder}
                  onCloseInstance={handleCloseInstance}
                  onDownloadCode={handleDownloadCode}
                  isLoading={isLoading}
                  setIsLoading={(loading: boolean) =>
                    dispatch(setIsLoading(loading))
                  }
                />
              </div>
            </>
          </div>

          <div className="card" ref={logsContainer}>
            <Logger testScript={testState.scriptSteps} logs={logs} />
          </div>
        </div>

        <Player url={playerUrl} />
      </div>
      <Sidebar />
      <EmulatorStatusModal
        isOpen={showStatusModal}
        onClose={() => {
          setShowStatusModal(false);
          setModalError("");
        }}
        status={statusData}
        error={modalError}
        onReset={async () => {
          setShowStatusModal(false);
          setModalError("");
          await handleReset();
        }}
      />
    </div>
  );
};
