import React, { useState } from "react";
import {
  Save,
  Play,
  Square,
  Upload,
  Smartphone,
  Files,
  GitBranch,
  ChevronRight,
  ChevronDown,
  File,
  Folder,
  Terminal,
  X,
  PanelLeftClose,
  Upload as UploadIcon,
  Wifi,
  Battery,
  Signal,
  FolderOpen,
  FileText
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuTrigger
} from "@radix-ui/react-dropdown-menu";

const devices = [
  { id: "iphone-15", name: "iPhone 15", width: 280, height: 580 },
  { id: "samsung-s24", name: "Samsung S24", width: 280, height: 580 },
  { id: "pixel-8", name: "Pixel 8", width: 280, height: 580 }
];

const FileExplorerItem = ({ item, level = 0 }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div
        className="flex items-center gap-2 text-sm py-1.5 px-2 hover:bg-accent rounded-md cursor-pointer group"
        style={{ paddingLeft: `${level * 12 + 8}px` }}
      >
        {item.type === "folder" ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className="flex items-center gap-2 flex-1 text-foreground"
          >
            <div className="flex items-center gap-2">
              {isOpen ? (
                <ChevronDown className="h-4 w-4 text-muted-foreground" />
              ) : (
                <ChevronRight className="h-4 w-4 text-muted-foreground" />
              )}
              {isOpen ? (
                <FolderOpen className="h-4 w-4 text-primary" />
              ) : (
                <Folder className="h-4 w-4 text-primary" />
              )}
            </div>
            <span>{item.name}</span>
            {item.children?.length > 0 && (
              <Badge variant="secondary" className="ml-2 h-4 px-1 text-xs">
                {item.children.length}
              </Badge>
            )}
          </button>
        ) : (
          <div className="flex items-center gap-2 flex-1 text-foreground">
            <FileText className="h-4 w-4 text-muted-foreground" />
            <span>{item.name}</span>
          </div>
        )}
      </div>
      {item.type === "folder" && isOpen && item.children && (
        <div className="py-1">
          {item.children.map((child, index) => (
            <FileExplorerItem key={index} item={child} level={level + 1} />
          ))}
        </div>
      )}
    </div>
  );
};

const TestEditor = () => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);

  const fileStructure = {
    type: "folder",
    name: "test_cases",
    children: [
      {
        type: "folder",
        name: "Authentication",
        children: [
          {
            type: "folder",
            name: "Login Tests",
            children: [
              { type: "file", name: "valid_login.test" },
              { type: "file", name: "invalid_credentials.test" }
            ]
          }
        ]
      },
      {
        type: "folder",
        name: "Payment",
        children: [
          { type: "file", name: "card_payment.test" },
          { type: "file", name: "refund_process.test" }
        ]
      }
    ]
  };

  return (
    <div className="h-screen bg-background flex flex-col">
      {/* Top Bar */}
      <div className="h-12 bg-card border-b border-border flex items-center justify-between px-4 shrink-0">
        <div className="flex items-center gap-3">
          <select className="h-8 rounded-md border border-border bg-accent text-foreground px-2 text-sm min-w-[120px]">
            <option>Instance 1</option>
            <option>Instance 2</option>
          </select>
          <Button
            variant="outline"
            size="sm"
            className="gap-2 text-foreground hover:text-primary hover:bg-accent"
          >
            <Upload className="h-4 w-4" />
            Upload Test
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="gap-2 text-foreground hover:text-primary hover:bg-accent"
              >
                <GitBranch className="h-4 w-4" />
                Sync
                <ChevronDown className="h-4 w-4 opacity-50" />
              </Button>
            </DropdownMenuTrigger>
          </DropdownMenu>
          <div className="h-4 w-px bg-border" />

          <Button
            variant="default"
            size="sm"
            className="gap-2 bg-blue-600 hover:bg-blue-700"
          >
            <Save className="h-4 w-4" />
            Save
          </Button>

          <div className="h-4 w-px bg-border" />
          <Button
            variant={isRunning ? "destructive" : "default"}
            size="sm"
            className="gap-2"
            onClick={() => setIsRunning(!isRunning)}
          >
            {isRunning ? (
              <>
                <Square className="h-4 w-4" />
                Stop Test
              </>
            ) : (
              <>
                <Play className="h-4 w-4" />
                Run Test
              </>
            )}
          </Button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex overflow-hidden">
        {/* Left Side: File Explorer */}
        {isLeftPanelOpen && (
          <div className="w-72 bg-card border-r border-border flex flex-col">
            <div className="flex items-center justify-between p-3 border-b border-border bg-accent/50">
              <div className="flex items-center gap-2">
                <Files className="h-4 w-4 text-foreground" />
                <span className="text-sm font-medium text-foreground">
                  EXPLORER
                </span>
              </div>
              <Button
                variant="secondary"
                size="icon"
                className="h-6 w-6 hover:bg-accent"
                onClick={() => setIsLeftPanelOpen(false)}
              >
                <PanelLeftClose className="h-4 w-4" />
              </Button>
            </div>
            <div className="flex-1 overflow-auto p-2">
              <FileExplorerItem item={fileStructure} />
            </div>
          </div>
        )}

        {/* Middle: Editor + Logs */}
        <div className="flex-1 flex min-w-0">
          <div className="flex-1 flex flex-col">
            {/* Editor Area */}
            <div className="flex-1 flex flex-col">
              {/* Editor Tabs */}
              <div className="h-10 border-b border-border flex items-center px-2 bg-card">
                <Button
                  variant="secondary"
                  size="icon"
                  className="h-6 w-6 mr-2 hover:bg-accent"
                  onClick={() => setIsLeftPanelOpen(true)}
                >
                  <Files className="h-4 w-4" />
                </Button>
                <div className="flex items-center">
                  <div className="flex items-center h-9 px-3 gap-2 bg-accent text-foreground border-t border-l border-r border-border rounded-t-md">
                    <File className="h-4 w-4" />
                    <span className="text-sm">login.test</span>
                    <X className="h-4 w-4 hover:text-destructive cursor-pointer" />
                  </div>
                </div>
              </div>

              {/* Code Editor Area */}
              <div className="flex-1 bg-background p-4 font-mono text-sm overflow-auto">
                <pre className="text-foreground">{`OPEN APP`}</pre>
              </div>
            </div>

            {/* Logs Panel */}
            <div className="h-40 bg-card border-t border-border flex flex-col">
              <div className="flex items-center justify-between p-2 border-b border-border bg-accent/50">
                <div className="flex items-center gap-2">
                  <Terminal className="h-4 w-4 text-foreground" />
                  <span className="text-sm font-medium text-foreground">
                    LOGS
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6 hover:bg-accent"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex-1 p-3 font-mono text-sm overflow-auto">
                <div className="space-y-2 text-sm">
                  <div className="text-green-500">
                    [✓] Step 1: App launched successfully
                  </div>
                  <div className="text-blue-400">
                    [i] Waiting for login screen...
                  </div>
                  <div className="text-foreground">
                    [→] Entering credentials...
                  </div>
                  {isRunning && (
                    <div className="text-blue-400 animate-pulse">
                      [i] Running test sequence...
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side: Device Preview - Full Height */}
          <div className="w-[380px] border-l border-border flex flex-col bg-card">
            <div className="flex flex-col border-b border-border bg-accent/50">
              <div className="flex items-center justify-between p-3">
                <div className="flex items-center gap-2">
                  <Smartphone className="h-4 w-4 text-foreground" />
                  <span className="text-sm font-medium text-foreground">
                    DEVICE PREVIEW
                  </span>
                </div>
              </div>
              <div className="px-3 pb-3 flex items-center gap-2">
                <select
                  className="flex-1 h-8 rounded-md border border-border bg-background text-foreground px-2 text-sm"
                  value={selectedDevice.id}
                  onChange={(e) =>
                    setSelectedDevice(
                      devices.find((d) => d.id === e.target.value)
                    )
                  }
                >
                  {devices.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-center bg-accent/10">
              {/* Mobile Device Frame */}
              <div className="relative bg-black rounded-[2rem] border-[8px] border-border h-[95%] aspect-[9/19]">
                {/* Status Bar */}
                <div className="absolute top-2 left-6 right-6 flex justify-between items-center">
                  <span className="text-xs text-white">12:30</span>
                  <div className="flex items-center gap-2">
                    <Signal className="h-3 w-3 text-white" />
                    <Wifi className="h-3 w-3 text-white" />
                    <Battery className="h-3 w-3 text-white" />
                  </div>
                </div>
                {/* Screen Content */}
                <div className="absolute inset-2 rounded-[1.8rem] bg-card flex items-center justify-center overflow-hidden">
                  {isRunning ? (
                    <div className="text-center space-y-3">
                      <div className="w-12 h-12 border-2 border-primary border-t-transparent rounded-full animate-spin mx-auto" />
                      <Badge variant="outline" className="animate-pulse">
                        Running Test...
                      </Badge>
                    </div>
                  ) : (
                    <div className="text-center space-y-3 px-6">
                      <UploadIcon className="h-8 w-8 mx-auto text-muted-foreground" />
                      <span className="text-sm text-muted-foreground block">
                        Upload test or press Run
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestEditor;
