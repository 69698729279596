import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import * as monaco from "monaco-editor";
import {
  initializeMonaco,
  getEditorConfig,
  setupEditorShortcuts,
  updateEditorOptions
} from "@/utils/monaco-utils";
import "@/style/editor.css";
import "@/style/monaco-editor.css";
import { ApiItem, MonacoEditorRef, StandardEditorProps } from "@/types";
import { useAppSelector } from "@/store/hooks";
import { selectApis } from "@/store/features/apiSlice";

const setupApiSuggestions = (() => {
  const disposables: monaco.IDisposable[] = [];

  return (apis: ApiItem[]) => {
    // Dispose of existing providers to avoid duplicates
    disposables.forEach((disposable) => disposable.dispose());
    disposables.length = 0;

    ["plaintext"].forEach((language) => {
      const disposable = monaco.languages.registerCompletionItemProvider(
        language,
        {
          triggerCharacters: [".", '"', "'"],
          provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn
            };

            // Create unique suggestions using Set
            const uniqueApis = Array.from(new Set(apis.map((api) => api.name)));

            const suggestions = uniqueApis.map((apiName) => {
              const api = apis.find((a) => a.name === apiName)!;
              return {
                label: api.name,
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: api.name,
                detail: `${api.method} ${api.url}`,
                range,
                commitCharacters: [",", " ", "\t", "\n"], // Characters that will commit the suggestion
                command: {
                  id: "editor.action.triggerSuggest",
                  title: "Re-trigger suggestions"
                }
              };
            });

            return { suggestions };
          }
        }
      );

      // Store disposable for cleanup
      disposables.push(disposable);
    });
  };
})();

export const StandardMonacoEditor = forwardRef<
  MonacoEditorRef,
  StandardEditorProps
>(
  (
    {
      value,
      onChange,
      height = "500px",
      language = "plaintext",
      theme = "vs-dark",
      isRunning = false
    },
    ref
  ) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const [editor, setEditor] =
      useState<monaco.editor.IStandaloneCodeEditor | null>(null);
    const apis = useAppSelector(selectApis);

    useImperativeHandle(ref, () => ({
      getValue: () => editor?.getValue() ?? "",
      setValue: (value: string) => editor?.setValue(value),
      editor: editor!
    }));

    useEffect(() => {
      let mounted = true;

      const init = async () => {
        await initializeMonaco();

        if (!mounted || !editorRef.current || editor) return;
        setupApiSuggestions(apis);

        const editorInstance = monaco.editor.create(editorRef.current, {
          ...getEditorConfig(value, language, theme, isRunning, false),
          glyphMargin: false,
          lineDecorationsWidth: undefined,
          folding: true,
          // Add these options explicitly
          quickSuggestions: { other: true, comments: true, strings: true },
          suggestOnTriggerCharacters: true,
          parameterHints: { enabled: true }
        });

        editorInstance.onDidChangeModelContent(() => {
          onChange(editorInstance.getValue());
        });

        setupEditorShortcuts(editorInstance);
        setEditor(editorInstance);
      };

      init();

      return () => {
        mounted = false;
        if (editor) {
          editor.dispose();
        }
      };
    }, []);

    useEffect(() => {
      if (editor) {
        updateEditorOptions(editor, isRunning);
      }
    }, [isRunning, editor]);

    useEffect(() => {
      if (apis.length > 0) {
        setupApiSuggestions(apis);
      }
    }, [apis]);

    useEffect(() => {
      if (editor && value !== editor.getValue()) {
        editor.setValue(value);
      }
    }, [value]);

    return (
      <div
        ref={editorRef}
        className={`monaco-editor-container ${theme}`}
        style={{
          height,
          width: "100%",
          overflow: "hidden",
          border: "1px solid #ccc",
          borderRadius: "4px",
          opacity: isRunning ? 0.7 : 1,
          pointerEvents: isRunning ? "none" : "auto"
        }}
      />
    );
  }
);

StandardMonacoEditor.displayName = "StandardMonacoEditor";
