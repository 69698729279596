import React from "react";
import { useAppSelector } from "@/store/hooks";
import { selectSingleModeLoading } from "@/store/features/editorSlice";
import { EditorToolbarProps } from "@/types";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Upload, Play, Square, Trash2, Download, X } from "lucide-react";
import { cn } from "@/lib/utils";

export const EditorToolbar: React.FC<EditorToolbarProps> = ({
  onRun,
  onStop,
  onClear,
  onFileUpload,
  isRunning,
  canStop,
  saveInstance,
  onSaveInstanceChange,
  isSingleLineMode,
  onSingleLineModeChange,
  isLoading,
  onCloseInstance,
  onDownloadCode
}) => {
  const isStopEnabled = canStop && isRunning;
  const isDisabled = isRunning || isLoading;
  const singleModeLoading = useAppSelector(selectSingleModeLoading);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith(".txt")) {
      alert("Please upload only text files (.txt)");
      event.target.value = "";
      return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target?.result as string;
      if (content) {
        onFileUpload(content);
      }
      event.target.value = "";
    };

    reader.onerror = function (error) {
      console.error("Error reading file:", error);
      alert("Error reading file");
      event.target.value = "";
    };

    reader.readAsText(file);
  };

  return (
    <div className="flex items-center gap-2 p-2 bg-black">
      {!isSingleLineMode && (
        <>
          {/* File Upload */}
          <div className="relative group">
            <Button
              variant="ghost"
              size="sm"
              disabled={isDisabled}
              className={cn(
                "h-7 px-2 text-[#858585] border border-white/10 transition-all duration-200",
                "hover:bg-blue-500/10 hover:text-blue-400 hover:border-blue-500/20",
                "group-hover:bg-blue-500/10 group-hover:text-blue-400 group-hover:border-blue-500/20",
                isDisabled &&
                  "opacity-50 cursor-not-allowed hover:bg-transparent hover:text-[#858585] hover:border-white/10 group-hover:bg-transparent group-hover:text-[#858585] group-hover:border-white/10"
              )}
            >
              <Upload className="h-4 w-4 mr-2" />
              Upload
            </Button>
            <input
              type="file"
              accept=".txt,.text"
              onChange={handleFileUpload}
              disabled={isDisabled}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer disabled:cursor-not-allowed hover:cursor-pointer"
            />
          </div>

          {/* Run Button */}
          <Button
            variant="outline"
            size="sm"
            onClick={onRun}
            disabled={isDisabled}
            className={cn(
              "bg-black border-green-800/30 text-green-500 hover:bg-green-900/10 hover:text-green-400",
              isDisabled && "opacity-50 cursor-not-allowed"
            )}
          >
            <Play className="h-4 w-4 mr-2" />
            Run
          </Button>

          {/* Stop Button */}
          <Button
            variant="outline"
            size="sm"
            onClick={onStop}
            disabled={!isStopEnabled}
            className={cn(
              "bg-black border-red-800/30 text-red-500 hover:bg-red-900/10 hover:text-red-400",
              !isStopEnabled && "opacity-50 cursor-not-allowed"
            )}
          >
            <Square className="h-4 w-4 mr-2" />
            Stop
          </Button>

          {/* Save Instance */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="saveInstance"
              checked={saveInstance}
              onCheckedChange={(checked) => {
                if (typeof checked === "boolean") {
                  onSaveInstanceChange(checked);
                }
              }}
              disabled={isDisabled}
              className="border-gray-800 data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500"
            />
            <Label htmlFor="saveInstance" className="text-sm text-gray-300">
              Save Instance
            </Label>
          </div>
        </>
      )}

      {/* Clear Button - Always visible */}
      <Button
        variant="outline"
        size="sm"
        onClick={onClear}
        className="bg-black text-gray-300 border-gray-800 hover:bg-gray-900 hover:text-gray-200 ml-auto"
      >
        <Trash2 className="h-4 w-4 mr-2" />
        Change Instance{" "}
      </Button>

      {/* Single Line Mode specific buttons */}
      {isSingleLineMode && (
        <>
          <Button
            variant="outline"
            size="sm"
            onClick={onDownloadCode}
            disabled={isLoading}
            className="bg-black text-gray-300 border-gray-800 hover:bg-gray-900 hover:text-gray-200"
          >
            <Download className="h-4 w-4 mr-2" />
            Download Script
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={onCloseInstance}
            disabled={isLoading}
            className="bg-black text-gray-300 border-gray-800 hover:bg-gray-900 hover:text-gray-200"
          >
            <X className="h-4 w-4 mr-2" />
            Close Instance
          </Button>
        </>
      )}
    </div>
  );
};
