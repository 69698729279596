import {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand,
  GetObjectCommand,
  DeleteObjectCommand
} from "@aws-sdk/client-s3";
import { ApiItem } from "@/types";

export class ApiStorage {
  private s3Client: S3Client;
  private bucketName: string;

  constructor() {
    this.s3Client = new S3Client({
      region: import.meta.env.VITE_AWS_REGION,
      credentials: {
        accessKeyId: import.meta.env.VITE_STORAGE_ACCESS_KEY,
        secretAccessKey: import.meta.env.VITE_STORAGE_ACCESS_VALUE
      }
    });
    this.bucketName = import.meta.env.VITE_STORAGE_BUCKET_NAME;

    if (!this.bucketName) {
      throw new Error("S3 bucket name is not configured");
    }
  }

  private getCompanyFolder(companyName: string): string {
    return companyName.toLowerCase().replace(/[^a-z0-9-]/g, "-");
  }

  async storeApi(api: ApiItem, companyName: string): Promise<void> {
    if (!companyName) {
      throw new Error("Company name is required");
    }

    const folderName = this.getCompanyFolder(companyName);
    const key = `${folderName}/${api.name}.json`;

    try {
      const command = new PutObjectCommand({
        Bucket: this.bucketName,
        Key: key,
        Body: JSON.stringify(api),
        ContentType: "application/json",
        Metadata: {
          "company-name": companyName,
          "last-modified": new Date().toISOString()
        }
      });

      await this.s3Client.send(command);
      console.log(
        `Successfully stored API ${api.id} for company ${companyName}`
      );
    } catch (error) {
      console.error("Error storing API in S3:", error);
      throw new Error(
        `Failed to store API: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  }

  async updateApi(
    apiId: string,
    updatedApi: ApiItem,
    companyName: string
  ): Promise<void> {
    if (!companyName) {
      throw new Error("Company name is required");
    }

    const folderName = this.getCompanyFolder(companyName);

    try {
      // First, check if the API exists
      const existingApis = await this.getApisByCompany(companyName);
      const existingApi = existingApis.find((api) => api.id === apiId);

      if (!existingApi) {
        throw new Error(
          `API with ID ${apiId} not found for company ${companyName}`
        );
      }

      // If the name has changed, we need to delete the old file
      if (existingApi.name !== updatedApi.name) {
        await this.deleteApi(existingApi.name, companyName);
      }

      // Store the updated API
      const key = `${folderName}/${updatedApi.name}.json`;

      const command = new PutObjectCommand({
        Bucket: this.bucketName,
        Key: key,
        Body: JSON.stringify({
          ...updatedApi,
          updated_at: new Date().toISOString()
        }),
        ContentType: "application/json",
        Metadata: {
          "company-name": companyName,
          "last-modified": new Date().toISOString()
        }
      });

      await this.s3Client.send(command);
      console.log(
        `Successfully updated API ${apiId} for company ${companyName}`
      );
    } catch (error) {
      console.error("Error updating API in S3:", error);
      throw new Error(
        `Failed to update API: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  }

  async getApisByCompany(companyName: string): Promise<ApiItem[]> {
    if (!companyName) {
      throw new Error("Company name is required");
    }

    const folderName = this.getCompanyFolder(companyName);

    try {
      console.log(
        `Fetching APIs for company: ${companyName} from folder: ${folderName}`
      );

      const listCommand = new ListObjectsCommand({
        Bucket: this.bucketName,
        Prefix: `${folderName}/`
      });

      const response = await this.s3Client.send(listCommand);

      if (!response.Contents || response.Contents.length === 0) {
        console.log(`No APIs found for company: ${companyName}`);
        return [];
      }

      const apis = await Promise.all(
        response.Contents.map(async (object) => {
          if (!object.Key) return null;

          const getCommand = new GetObjectCommand({
            Bucket: this.bucketName,
            Key: object.Key
          });

          try {
            const data = await this.s3Client.send(getCommand);
            const bodyContents = await data.Body?.transformToString();
            if (!bodyContents) {
              console.warn(`Empty response for API: ${object.Key}`);
              return null;
            }
            return JSON.parse(bodyContents) as ApiItem;
          } catch (error) {
            console.error(
              `Error fetching individual API ${object.Key}:`,
              error
            );
            return null;
          }
        })
      );

      // Filter out any null values from failed fetches
      const validApis = apis.filter((api): api is ApiItem => api !== null);
      console.log(
        `Successfully fetched ${validApis.length} APIs for company ${companyName}`
      );

      return validApis;
    } catch (error) {
      console.error("Error fetching APIs from S3:", error);
      throw new Error(
        `Failed to fetch APIs: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  }

  async deleteApi(apiId: string, companyName: string): Promise<void> {
    if (!companyName) {
      throw new Error("Company name is required");
    }

    const folderName = this.getCompanyFolder(companyName);
    const key = `${folderName}/${apiId}.json`;

    try {
      const command = new DeleteObjectCommand({
        Bucket: this.bucketName,
        Key: key
      });

      await this.s3Client.send(command);
      console.log(
        `Successfully deleted API ${apiId} for company ${companyName}`
      );
    } catch (error) {
      console.error("Error deleting API from S3:", error);
      throw new Error(
        `Failed to delete API: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  }
}
