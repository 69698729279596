import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog";
import { AlertTriangle, XCircle } from "lucide-react";

interface Status {
  instance_name: string;
  emulator_status: string;
  test_status: string;
  provider?: string;
}

interface EmulatorStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: Status | null;
  onReset: () => void;
  error?: string;
}

const EmulatorStatusModal = ({
  isOpen,
  onClose,
  status,
  onReset,
  error
}: EmulatorStatusModalProps) => {
  const isErrorMode = !!error;

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="bg-gray-900 border border-gray-800">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-white flex items-center gap-2">
            {isErrorMode ? (
              <>
                <XCircle className="h-5 w-5 text-red-500" />
                Instance Error
              </>
            ) : (
              <>
                <AlertTriangle className="h-5 w-5 text-yellow-500" />
                Existing Emulator Found
              </>
            )}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-gray-300">
            {isErrorMode ? (
              <div className="space-y-4">
                <div className="bg-red-500/10 border border-red-500/20 rounded-md p-4">
                  <p className="text-red-400">{error}</p>
                </div>
                <p>
                  Please try using a different instance name or contact support
                  if the issue persists.
                </p>
              </div>
            ) : (
              <div className="space-y-4">
                <p>
                  An emulator instance is already running with the following
                  status:
                </p>

                <div className="bg-gray-800 p-4 rounded-md space-y-2">
                  <div className="grid grid-cols-2 gap-2">
                    <span className="text-gray-400">Instance Name:</span>
                    <span className="text-white">{status?.instance_name}</span>

                    <span className="text-gray-400">Emulator Status:</span>
                    <span
                      className={
                        status?.emulator_status === "on"
                          ? "text-green-500"
                          : "text-yellow-500"
                      }
                    >
                      {status?.emulator_status}
                    </span>

                    <span className="text-gray-400">Test Status:</span>
                    <span
                      className={
                        status?.test_status === "waiting"
                          ? "text-yellow-500"
                          : status?.test_status === "completed"
                          ? "text-green-500"
                          : "text-white"
                      }
                    >
                      {status?.test_status}
                    </span>
                  </div>
                </div>

                <p>
                  Would you like to reset the existing instance and start a new
                  one?
                </p>
              </div>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-gray-800 text-white hover:bg-gray-700">
            {isErrorMode ? "Close" : "Cancel"}
          </AlertDialogCancel>
          {!isErrorMode && (
            <AlertDialogAction
              onClick={onReset}
              className="bg-blue-600 text-white hover:bg-blue-700"
            >
              Reset Instance
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default EmulatorStatusModal;
