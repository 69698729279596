import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ApiResponse } from "@/types";

interface ApiResponseViewerProps {
  response: ApiResponse | null;
  apiName: string;
  isNewApi?: boolean;
  hasTestedApi?: boolean;
  onSave?: () => void;
}

export function ApiResponseViewer({
  response,
  apiName,
  isNewApi,
  hasTestedApi,
  onSave
}: ApiResponseViewerProps) {
  if (!response) return null;

  return (
    <Card className="mt-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <div className="space-y-1">
          <CardTitle className="text-lg">Response</CardTitle>
          <p className="text-sm text-muted-foreground">
            {apiName.toUpperCase()}
          </p>
        </div>
        <Badge
          variant={
            response.status >= 200 && response.status < 300
              ? "default"
              : "destructive"
          }
          className={
            response.status >= 200 && response.status < 300
              ? "bg-green-500"
              : ""
          }
        >
          {response.status} {response.statusText}
        </Badge>
      </CardHeader>
      <CardContent className="space-y-4">
        {response.error ? (
          <div className="space-y-2">
            <h3 className="font-medium text-destructive">Error</h3>
            <div className="bg-destructive/10 text-destructive p-3 rounded-md">
              <p>{response.error.message}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="space-y-2">
              <h3 className="font-medium">Headers</h3>
              <pre className="bg-muted p-3 rounded-md overflow-x-auto text-sm">
                {JSON.stringify(response.headers, null, 2)}
              </pre>
            </div>

            <div className="space-y-2">
              <h3 className="font-medium">Response Data</h3>
              <pre className="bg-muted p-3 rounded-md overflow-x-auto text-sm">
                {JSON.stringify(response.data, null, 2)}
              </pre>
            </div>
          </>
        )}
      </CardContent>
      {isNewApi && hasTestedApi && (
        <div className="px-6 pb-4">
          <Button className="w-full" onClick={onSave}>
            Save API
          </Button>
        </div>
      )}
    </Card>
  );
}
