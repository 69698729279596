import * as z from "zod";
export type TabType = "list" | "add" | "edit" | "variables" | null;

export interface ApiItem extends ApiFormData {
  id: string;
  created_at: string;
  updated_at: string;
}

export interface HeaderField {
  id?: string;
  type?: string;
  key?: string;
  description?: string;
  example?: string;
  default?: string;
}

export interface Headers {
  [key: string]: HeaderField;
}

export interface ParamField {
  id?: string;
  type?: string;
  key?: string;
  description?: string;
  example?: string;
  default?: string;
}

export interface Params {
  [key: string]: ParamField;
}

export interface ApiFormData {
  name: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  headers: Headers;
  params: Params;
  body: string;
  responses?: ApiResponse;
}

export interface SavedResponse {
  id: string; // Unique identifier for the saved response
  name: string; // User-given name for the response
  savedAt: string; // Timestamp when response was saved
  status: number; // HTTP status code
  statusText: string; // HTTP status text
  headers: any; // Response headers
  data: any; // Response data
  error: null | {
    // Error information if any
    message: string;
    stack?: string;
  };
}
export interface ApiState {
  apis: ApiItem[];
  selectedApi: ApiItem | null;
  executingApiId: string | null;
  isLoading: boolean;
  error: string | null;
  sidebarExpanded: boolean;
  activeTab: TabType;
  variables: GlobalVariable[];
}

export interface ApiResponse {
  status: number;
  statusText: string;
  headers: any;
  data: any;
  error: null | {
    message: string;
    stack?: string;
  };
}

export const toSnakeCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_]/g, "");
};

export const headerFieldSchema = z.object({
  id: z.string(),
  type: z.string(),
  key: z.string(),
  description: z.string(),
  example: z.string().optional(),
  default: z.string()
});

export const paramFieldSchema = z.object({
  id: z.string(),
  type: z.string(),
  key: z.string(),
  description: z.string(),
  example: z.string().optional(),
  default: z.string()
});

// Zod schema

export const formSchema = z.object({
  name: z
    .string()
    .min(1, "Name is required")
    .transform((value) => toSnakeCase(value)),
  method: z.enum(["GET", "POST", "PUT", "PATCH", "DELETE"]),
  url: z.string(),
  headers: z.record(headerFieldSchema).default({}),
  params: z.record(paramFieldSchema).default({}),
  body: z.string().default("")
});

export type FormValues = z.infer<typeof formSchema>;

export interface GlobalVariable {
  id: string;
  key: string;
  value: string;
  is_secret: boolean;
}

export interface GlobalVariablesState {
  variables: GlobalVariable[];
}
