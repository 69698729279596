export const getJWTToken = async (instanceUuid: string): Promise<string> => {
  const baseUrlToFetch = "https://api.geny.io/cloud";
  const apiToken = import.meta.env.VITE_API_TOKEN;

  // Validate API token exists
  if (!apiToken) {
    throw new Error("API Token is not configured");
  }

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "x-api-token": apiToken
  };

  const payload = {
    instance_uuid: instanceUuid
  };

  try {
    const response = await fetch(
      `${baseUrlToFetch}/v1/instances/access-token`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(payload)
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch JWT token");
    }

    const { access_token } = await response.json();
    return access_token;
  } catch (error) {
    console.error("Error fetching JWT token:", error);
    throw error;
  }
};
