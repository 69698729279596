import { Auth0Provider } from "@auth0/auth0-react";
import { ProtectedPageWrapper } from "@/components/PageWrapper";
import { UnauthorizedPage } from "@/screens/Auth/UnauthorizedPage";
import { Dashboard } from "@/screens/Dashboard/index";
import { LoginPage } from "@/screens/Auth/LoginPage";
import { StartPage } from "@/screens/Start/index";
import { ProjectListScreen } from "@/screens/project/ProjectListScreen/index";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { ProtectedRoute, PublicRoute } from "./routes";
import { ProjectCreateScreen } from "./screens/project/ProjectNewScreen/index";
import { ProjectFilesScreen } from "./screens/project/ProjectFilesScreen";
import ProjectsDashboard from "./screens/demo/ProjectsDashboard";
import CreateProject from "./screens/demo/CreateProject";
import TestFileExplorer from "./screens/demo/ProjectItem";
import ProjectSettings from "./screens/demo/ProjectSettings";
import CreateTestScreen from "./screens/demo/CreateTestScreen";
import TestPlanExplorer from "./screens/demo/TestPlanScreen";
import CreateTestPlanScreen from "./screens/demo/CreateTestScreen";
import CreateTestPlan from "./screens/demo/CreateTestPlan";
import TestPlanDetails from "./screens/demo/TestPlanDetails";
import AppLayout from "./screens/demo/components/Layout";
import NotFound from "./screens/NotFound";
import TestEditor from "./screens/demo/Dashboard";

function App() {
  return (
    <div className="dark bg-background">
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/dashboard`,
          scope: "openid profile email",
          response_type: "token id_token"
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/unauthorized"
              element={
                <ProtectedRoute>
                  <UnauthorizedPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedPageWrapper>
                  <Dashboard />
                </ProtectedPageWrapper>
              }
            />
            <Route
              path="/start"
              element={
                <ProtectedPageWrapper>
                  <StartPage />
                </ProtectedPageWrapper>
              }
            />
            {/* <Route
              path="/project"
              element={
                <ProtectedPageWrapper>
                  <ProjectListScreen />
                </ProtectedPageWrapper>
              }
            />
            <Route
              path="/project/:id"
              element={
                <ProtectedPageWrapper>
                  <ProjectFilesScreen />
                </ProtectedPageWrapper>
              }
            />
            <Route
              path="/project/create"
              element={
                <ProtectedPageWrapper>
                  <ProjectCreateScreen />
                </ProtectedPageWrapper>
              }
            /> */}
            {/* Dummy UI Start Here */}
            <Route
              path="/demo/project/test/new"
              element={
                <AppLayout>
                  <CreateTestScreen onBack={undefined} />
                </AppLayout>
              }
            />
            <Route
              path="/demo/testplan/:id"
              element={
                <AppLayout>
                  <TestPlanDetails />
                </AppLayout>
              }
            />
            <Route
              path="/demo/testplan/new"
              element={
                <AppLayout>
                  <CreateTestPlan />
                </AppLayout>
              }
            />
            <Route
              path="/demo/testplan"
              element={
                <AppLayout>
                  <TestPlanExplorer />
                </AppLayout>
              }
            />
            <Route
              path="/demo/project/create"
              element={
                <AppLayout>
                  <CreateProject />
                </AppLayout>
              }
            />
            <Route
              path="/demo/project/setting"
              element={
                <AppLayout>
                  <ProjectSettings />
                </AppLayout>
              }
            />
            <Route
              path="/demo/project/:23"
              element={
                <AppLayout>
                  <TestFileExplorer />
                </AppLayout>
              }
            />
            <Route
              path="/demo/project"
              element={
                <AppLayout>
                  <ProjectsDashboard />
                </AppLayout>
              }
            />
            <Route path="/demo/playground" element={<TestEditor />} />

            {/* End Here */}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Auth0Provider>
    </div>
  );
}

export default App;
