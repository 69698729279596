import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import {
  Folder,
  Github,
  Smartphone,
  Calendar,
  MoreVertical,
  PlayCircle,
  Plus
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Navigate } from "react-router-dom";

const ProjectsDashboard = () => {
  const projects = [
    {
      id: 1,
      name: "E-commerce App Tests",
      testCases: 24,
      lastSync: "2024-12-10",
      githubConnected: true,
      lastRun: "2024-12-11"
    },
    {
      id: 2,
      name: "Payment Gateway Tests",
      testCases: 16,
      lastSync: "2024-12-09",
      githubConnected: true,
      lastRun: "2024-12-10"
    },
    {
      id: 3,
      name: "User Authentication Flow",
      testCases: 12,
      lastSync: "2024-12-08",
      githubConnected: false,
      lastRun: "2024-12-09"
    }
  ];

  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20 ">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h1 className="text-3xl font-bold mb-2 text-foreground">
                Projects
              </h1>
              <p className="text-muted-foreground">
                Manage your mobile app test cases
              </p>
            </div>
            <Button className="gap-2">
              <Plus className="h-4 w-4" />
              Create Project
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <Card
              key={project.id}
              className="hover:bg-secondary/50 transition-colors border border-border"
            >
              <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="flex items-center gap-2 text-foreground">
                  <Folder className="h-5 w-5" />
                  <span>{project.name}</span>
                </CardTitle>
                <DropdownMenu>
                  <DropdownMenuTrigger className="focus:outline-none">
                    <MoreVertical className="h-5 w-5 text-muted-foreground hover:text-foreground" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem>Edit Project</DropdownMenuItem>
                    <DropdownMenuItem>Delete Project</DropdownMenuItem>
                    <DropdownMenuItem>Sync with GitHub</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </CardHeader>

              <CardContent>
                <div className="space-y-4">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-muted-foreground">Test Cases</span>
                    <span className="text-foreground font-medium">
                      {project.testCases}
                    </span>
                  </div>

                  <div className="flex items-center justify-between text-sm">
                    <span className="text-muted-foreground">Last Run</span>
                    <div className="flex items-center gap-2 text-foreground">
                      <Calendar className="h-4 w-4" />
                      <span>{project.lastRun}</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between text-sm">
                    <span className="text-muted-foreground">GitHub Status</span>
                    <div className="flex items-center gap-2 text-foreground">
                      <Github className="h-4 w-4" />
                      <span>
                        {project.githubConnected
                          ? "Connected"
                          : "Not Connected"}
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>

              <CardFooter className="flex justify-between pt-4 border-t border-border">
                <button className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors">
                  <Smartphone className="h-4 w-4" />
                  <span>View Tests</span>
                </button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectsDashboard;
