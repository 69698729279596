import { configureStore } from "@reduxjs/toolkit";
import editorReducer from "./features/editorSlice";
import apiReducer from "./features/apiSlice";

export const store = configureStore({
  reducer: {
    editor: editorReducer,
    api: apiReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["api/executeApi/pending"]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
