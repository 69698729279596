import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Github, Folder, FileCode, ArrowLeft } from "lucide-react";

const CreateProject = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        <div className="flex items-center gap-4 mb-8">
          <Button variant="ghost" size="icon">
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div>
            <h1 className="text-3xl font-bold text-foreground">
              Create Project
            </h1>
            <p className="text-muted-foreground">
              Add a new test project to Drizz
            </p>
          </div>
        </div>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Folder className="h-5 w-5" />
              Project Details
            </CardTitle>
            <CardDescription>Set up your new test project</CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            {/* Basic Project Details */}
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="projectName">Project Name</Label>
                <Input id="projectName" placeholder="Enter project name" />
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Input
                  id="description"
                  placeholder="Brief project description"
                />
              </div>
            </div>

            {/* GitHub Integration Section */}
            <div className="space-y-4 pt-4 border-t border-border">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-medium text-foreground">
                    GitHub Connection
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    Link a repository to sync your test cases
                  </p>
                </div>
                <Button variant="outline" className="flex items-center gap-2">
                  <Github className="h-4 w-4" />
                  Connect GitHub
                </Button>
              </div>

              <div className="space-y-2">
                <Label htmlFor="repo">Repository</Label>
                <select
                  id="repo"
                  className="w-full h-10 px-3 rounded-md border bg-background text-foreground"
                  disabled
                >
                  <option value="">Select a repository</option>
                </select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="branch">Branch</Label>
                <select
                  id="branch"
                  className="w-full h-10 px-3 rounded-md border bg-background text-foreground"
                  disabled
                >
                  <option value="">Select a branch</option>
                </select>
              </div>

              <div className="flex items-center gap-2 p-3 rounded-md bg-secondary/50">
                <FileCode className="h-5 w-5 text-muted-foreground" />
                <span className="text-sm text-muted-foreground">
                  Connect GitHub to select a repository
                </span>
              </div>
            </div>

            {/* Create Button */}
            <div className="pt-4">
              <Button className="w-full">Create Project</Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CreateProject;
