import React, { useState } from "react";
import { Eye, EyeOff, Plus, Save, Trash2 } from "lucide-react";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Card, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  addVariable,
  deleteVariable,
  selectGlobalVariables,
  updateVariable
} from "@/store/features/apiSlice";
import { GlobalVariable } from "@/types";

export function GlobalVariablesManager() {
  const dispatch = useAppDispatch();
  const variables = useAppSelector(selectGlobalVariables);
  const [showSecretValues, setShowSecretValues] = useState<{
    [key: string]: boolean;
  }>({});
  const [newVariable, setNewVariable] = useState({
    key: "",
    value: "",
    is_secret: false
  });

  const handleAddVariable = () => {
    if (newVariable.key.trim()) {
      dispatch(addVariable(newVariable));
      setNewVariable({ key: "", value: "", is_secret: false });
    }
  };

  const toggleSecretVisibility = (id: string) => {
    setShowSecretValues((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleUpdateVariable = (
    id: string,
    field: keyof GlobalVariable,
    value: string | boolean
  ) => {
    dispatch(
      updateVariable({
        id,
        data: { [field]: value }
      })
    );
  };

  return (
    <div className="space-y-6">
      {/* Add New Variable */}
      <div className="space-y-4">
        <div className="space-y-2">
          <Input
            placeholder="Variable Name"
            value={newVariable.key}
            onChange={(e) =>
              setNewVariable((prev) => ({ ...prev, key: e.target.value }))
            }
          />
          <Input
            type={newVariable.is_secret ? "password" : "text"}
            placeholder="Value"
            value={newVariable.value}
            onChange={(e) =>
              setNewVariable((prev) => ({ ...prev, value: e.target.value }))
            }
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="new-secret"
              checked={newVariable.is_secret}
              onCheckedChange={(checked) =>
                setNewVariable((prev) => ({
                  ...prev,
                  is_secret: checked as boolean
                }))
              }
            />
            <Label htmlFor="new-secret" className="text-sm">
              Secret
            </Label>
          </div>
          <Button
            type="button"
            size="sm"
            onClick={handleAddVariable}
            disabled={!newVariable.key.trim()}
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Variable
          </Button>
        </div>
      </div>

      {/* Variable List */}
      <div className="space-y-4">
        {variables.map((variable) => (
          <Card key={variable.id} className="relative">
            <CardContent className="pt-4 space-y-2">
              <div className="space-y-2">
                <Input
                  value={variable.key}
                  onChange={(e) =>
                    handleUpdateVariable(variable.id, "key", e.target.value)
                  }
                />
                <div className="relative">
                  <Input
                    type={
                      variable.is_secret && !showSecretValues[variable.id]
                        ? "password"
                        : "text"
                    }
                    value={variable.value}
                    onChange={(e) =>
                      handleUpdateVariable(variable.id, "value", e.target.value)
                    }
                  />
                  {variable.is_secret && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
                      onClick={() => toggleSecretVisibility(variable.id)}
                    >
                      {showSecretValues[variable.id] ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id={`secret-${variable.id}`}
                    checked={variable.is_secret}
                    onCheckedChange={(checked) =>
                      handleUpdateVariable(
                        variable.id,
                        "is_secret",
                        checked as boolean
                      )
                    }
                  />
                  <Label htmlFor={`secret-${variable.id}`} className="text-sm">
                    Secret
                  </Label>
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => dispatch(deleteVariable(variable.id))}
                  className="h-8 w-8 p-0"
                >
                  <Trash2 className="h-4 w-4 text-destructive" />
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
