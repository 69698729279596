import React, { useState } from "react";
import {
  FileText,
  PlayCircle,
  Settings,
  Menu,
  ChevronDown,
  Bell,
  LogOut,
  Layout,
  Home,
  History,
  PanelLeftClose,
  PanelLeft,
  Plus
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const AppLayout = ({ children }) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const navItems = [
    { icon: Home, label: "Dashboard", href: "/" },
    { icon: FileText, label: "Test Explorer", href: "/tests" },
    { icon: PlayCircle, label: "Test Plans", href: "/plans" },
    { icon: History, label: "Executions", href: "/executions" },
    { icon: Settings, label: "Settings", href: "/settings" }
  ];

  return (
    <div className="min-h-screen bg-background overflow-hidden">
      {/* Sidebar */}
      <aside
        className={`fixed left-0 top-0 bottom-0 z-30 flex flex-col border-r border-border bg-card transition-all duration-300 overflow-hidden ${
          isSidebarCollapsed ? "w-16" : "w-64"
        }`}
      >
        {/* Logo Area */}
        <div className="h-14 flex items-center px-4 border-b border-border">
          {!isSidebarCollapsed && (
            <div className="flex items-center gap-2">
              <Layout className="h-6 w-6 text-blue-500" />
              <span className="font-bold text-lg">Drizz</span>
            </div>
          )}
          <Button
            variant="secondary"
            size="icon"
            className={`${isSidebarCollapsed ? "mx-auto" : "ml-auto"}`}
            onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
          >
            {isSidebarCollapsed ? (
              <PanelLeft className="h-4 w-4" />
            ) : (
              <PanelLeftClose className="h-4 w-4" />
            )}
          </Button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto py-4">
          {navItems.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="flex items-center gap-3 px-4 py-2 text-muted-foreground hover:text-foreground hover:bg-secondary/50 transition-colors"
            >
              <item.icon className="h-5 w-5" />
              {!isSidebarCollapsed && <span>{item.label}</span>}
            </a>
          ))}
        </nav>

        {/* User Menu */}
        <div className="p-4 border-t border-border">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className={`w-full ${
                  isSidebarCollapsed ? "justify-center" : "justify-start"
                }`}
              >
                <div className="flex items-center gap-2">
                  <Avatar className="h-6 w-6">
                    <AvatarImage src="https://github.com/shadcn.png" />
                    <AvatarFallback>CN</AvatarFallback>
                  </Avatar>
                  {!isSidebarCollapsed && (
                    <>
                      <span className="text-sm">John Doe</span>
                      <ChevronDown className="h-4 w-4 ml-auto" />
                    </>
                  )}
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-56">
              <DropdownMenuItem>Profile Settings</DropdownMenuItem>
              <DropdownMenuItem>Team Management</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="text-destructive">
                <LogOut className="h-4 w-4 mr-2" />
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </aside>

      {/* Main Content Area */}
      <div
        className={`transition-all duration-300 ${
          isSidebarCollapsed ? "ml-16" : "ml-64"
        } min-h-screen`}
      >
        {/* Header */}
        <header className="h-14 border-b border-border bg-card fixed top-0 right-0 left-0 z-20 flex items-center px-4">
          <div
            className={`${
              isSidebarCollapsed ? "ml-16" : "ml-64"
            } flex-1 flex items-center justify-between pr-4`}
          >
            <div className="flex items-center gap-2">{/* Start Item */}</div>

            <div className="flex items-center gap-2">
              <Button variant="secondary" size="icon" className="relative">
                <Bell className="h-5 w-5" />
                <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full" />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="default" size="sm" className="gap-2">
                    <Menu className="h-4 w-4" />
                    Project
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem>E-commerce App Tests</DropdownMenuItem>
                  <DropdownMenuItem>Payment Gateway Tests</DropdownMenuItem>
                  <DropdownMenuItem>User Authentication Tests</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <Plus className="h-4 w-4 mr-2" />
                    Create New Project
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="pt-14 pb-80">{children}</main>
      </div>
    </div>
  );
};

export default AppLayout;
