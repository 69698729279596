import React, { useState } from "react";
import {
  ChevronRight,
  ChevronDown,
  Folder,
  File,
  ArrowLeft,
  Save,
  Smartphone,
  Settings,
  Check,
  Plus
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const initialFiles = {
  id: "root",
  name: "Test Cases",
  type: "folder",
  children: [
    {
      id: "1",
      name: "Authentication",
      type: "folder",
      children: [
        {
          id: "1-1",
          name: "Login Tests",
          type: "folder",
          children: [
            { id: "1-1-1", name: "valid_login.test", type: "file" },
            { id: "1-1-2", name: "invalid_credentials.test", type: "file" }
          ]
        },
        { id: "1-2", name: "signup_flow.test", type: "file" }
      ]
    },
    {
      id: "2",
      name: "Payment",
      type: "folder",
      children: [
        { id: "2-1", name: "card_payment.test", type: "file" },
        { id: "2-2", name: "refund_process.test", type: "file" }
      ]
    }
  ]
};

// FileTreeNode component with checkbox selection
const FileTreeNode = ({ node, level = 0, selectedItems, onToggleSelect }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isSelected = selectedItems.includes(node.id);

  return (
    <div>
      <div
        className={`group relative flex items-center py-2.5 px-4 hover:bg-secondary/50 rounded-md cursor-pointer ${
          isSelected ? "bg-secondary/50" : ""
        }`}
        style={{ paddingLeft: `${level * 20}px` }}
        onClick={() => onToggleSelect(node)}
      >
        <div className="flex items-center flex-1 gap-2">
          {node.type === "folder" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className="p-1 hover:bg-secondary rounded-md"
            >
              {isOpen ? (
                <ChevronDown className="h-4 w-4 text-foreground transition-transform" />
              ) : (
                <ChevronRight className="h-4 w-4 text-foreground transition-transform" />
              )}
            </button>
          )}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onToggleSelect(node)}
              className="rounded border-gray-400"
            />
            {node.type === "folder" ? (
              <Folder className="h-4 w-4 text-blue-500" />
            ) : (
              <File className="h-4 w-4 text-blue-400" />
            )}
            <span className="text-sm text-foreground font-medium">
              {node.name}
            </span>
          </div>
        </div>
      </div>

      {node.type === "folder" && isOpen && node.children && (
        <div className="pl-4">
          {node.children.map((child) => (
            <FileTreeNode
              key={child.id}
              node={child}
              level={level + 1}
              selectedItems={selectedItems}
              onToggleSelect={onToggleSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CreateTestPlan = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleToggleSelect = (node) => {
    setSelectedItems((prev) =>
      prev.includes(node.id)
        ? prev.filter((id) => id !== node.id)
        : [...prev, node.id]
    );
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header */}
        <div className="flex items-center gap-4 mb-8">
          <Button variant="secondary" size="icon">
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div className="flex-1">
            <h1 className="text-3xl font-bold text-foreground">
              Create Test Plan
            </h1>
            <p className="text-muted-foreground mt-1">
              Configure your test execution plan
            </p>
          </div>
          <Button className="gap-2">
            <Save className="h-4 w-4" />
            Save Plan
          </Button>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-3 gap-6">
          {/* Left Column - Basic Info */}
          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>Basic Information</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="planName">Plan Name</Label>
                  <Input
                    id="planName"
                    placeholder="e.g., Regression Test v1.0"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="project">Project</Label>
                  <select className="w-full h-10 rounded-md border bg-background px-3">
                    <option>E-commerce App Tests</option>
                    <option>Payment Gateway Tests</option>
                    <option>User Authentication Tests</option>
                  </select>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Device Configuration</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2">
                  <Label>Device Type</Label>
                  <div className="grid grid-cols-2 gap-2">
                    <Button variant="outline" className="justify-start">
                      <Smartphone className="h-4 w-4 mr-2" />
                      Emulator
                    </Button>
                    <Button variant="outline" className="justify-start">
                      <Smartphone className="h-4 w-4 mr-2" />
                      Real Device
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label>Device Farm</Label>
                  <select className="w-full h-10 rounded-md border bg-background px-3">
                    <option>Browserstack</option>
                    <option>Lambdatest</option>
                    <option>In-house Farm</option>
                  </select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label>Device Model</Label>
                    <select className="w-full h-10 rounded-md border bg-background px-3">
                      <option>Samsung S24</option>
                      <option>iPhone 15</option>
                    </select>
                  </div>
                  <div className="space-y-2">
                    <Label>OS Version</Label>
                    <select className="w-full h-10 rounded-md border bg-background px-3">
                      <option>Android 14</option>
                      <option>iOS 17</option>
                    </select>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label>Authentication Key</Label>
                  <Input
                    type="password"
                    placeholder="Enter device farm auth key"
                  />
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Right Column - Test Selection & Config */}
          <div className="col-span-2 space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>Test Selection</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="bg-card border rounded-lg h-[400px] overflow-auto">
                  <FileTreeNode
                    node={initialFiles}
                    selectedItems={selectedItems}
                    onToggleSelect={handleToggleSelect}
                  />
                </div>
                <div className="flex items-center justify-between px-4 py-2 bg-secondary/50 rounded-md">
                  <span className="text-sm text-muted-foreground">
                    Selected:
                  </span>
                  <Badge variant="secondary">
                    {selectedItems.length} items
                  </Badge>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Emulator Settings</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-2 gap-6">
                  <div className="space-y-3">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>Location Access</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>Notification Access</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>Battery Optimization</span>
                    </label>
                  </div>
                  <div className="space-y-3">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>Play Store Access</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>File/Gallery Access</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-400"
                      />
                      <span>Overlay Permissions</span>
                    </label>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTestPlan;
