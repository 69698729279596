import * as Sentry from "@sentry/react";
export const retryFetchOperation = async <T>(
  operation: () => Promise<T>,
  {
    maxRetries = 2,
    delayMs = 1000,
    onRetry = (attempt: number) => console.log(`Retry attempt ${attempt}`),
    context = {} // Additional context to be sent to Sentry
  } = {}
): Promise<T> => {
  let lastError: Error;
  const startTime = Date.now();

  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      if (error instanceof Error) {
        lastError = error;
        const duration = Date.now() - startTime;

        const isRetryableError =
          error.name === "TypeError" || // Network errors
          (error instanceof Response && error.status >= 500) ||
          error.message.includes("Failed to fetch") ||
          error.message.includes("Network Error");

        // Capture detailed error info in Sentry
        Sentry.withScope((scope) => {
          // Add retry attempt information
          scope.setExtra("attemptNumber", attempt + 1);
          scope.setExtra("maxRetries", maxRetries);
          scope.setExtra("isRetryableError", isRetryableError);
          scope.setExtra("duration", duration);
          scope.setExtra("delayMs", delayMs);

          // Add error specific details
          if (error instanceof Response) {
            scope.setExtra("statusCode", error.status);
            scope.setExtra("statusText", error.statusText);
          }

          // Add any additional context passed to the function
          Object.entries(context).forEach(([key, value]) => {
            scope.setExtra(key, value);
          });

          // Set error level based on attempt
          scope.setLevel(attempt === maxRetries ? "error" : "warning");

          // Add custom fingerprint to group related errors
          scope.setFingerprint([
            "retry-operation",
            error.name,
            error.message,
            isRetryableError.toString()
          ]);

          // Add tags for better filtering
          scope.setTags({
            operation: "fetch",
            retryAttempt: `${attempt + 1}`,
            errorType: error.name,
            isRetryable: isRetryableError.toString()
          });

          // Capture the error with additional context
          Sentry.captureException(error, {
            extra: {
              attemptDetails: `Attempt ${attempt + 1} of ${maxRetries + 1}`,
              errorTimestamp: new Date().toISOString(),
              totalDuration: duration
            }
          });
        });

        if (attempt === maxRetries || !isRetryableError) {
          // On final attempt, add extra context before throwing
          if (attempt === maxRetries) {
            Sentry.withScope((scope) => {
              scope.setExtra("finalError", true);
              scope.setExtra("totalAttempts", attempt + 1);
              scope.setExtra("totalDuration", Date.now() - startTime);
              scope.setLevel("error");
              Sentry.captureMessage("Maximum retry attempts reached", "error");
            });
          }
          throw error;
        }

        onRetry(attempt + 1);
        await new Promise((resolve) =>
          setTimeout(resolve, delayMs * (attempt + 1))
        );
      } else {
        // Handle non-Error objects
        Sentry.withScope((scope) => {
          scope.setExtra("nonErrorType", typeof error);
          scope.setExtra("errorValue", String(error));
          scope.setLevel("error");
          Sentry.captureMessage(
            "Non-Error object thrown in retry operation",
            "error"
          );
        });
        throw error;
      }
    }
  }

  throw lastError!;
};
