import { ErrorBoundary } from "@sentry/react";
import { ProtectedRoute } from "@/routes/ProtectedRoute";
import { PermissionRoute } from "@/routes/PermissionRoute";

export const ProtectedPageWrapper = ({ children }) => {
  return (
    <ProtectedRoute>
      <PermissionRoute>
        <ErrorBoundary>{children}</ErrorBoundary>
      </PermissionRoute>
    </ProtectedRoute>
  );
};
