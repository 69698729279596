import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Settings,
  Github,
  Users,
  Shield,
  Trash2,
  Save,
  ChevronRight,
  Globe,
  Bell,
  FolderCog
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";

const ProjectSettings = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center gap-2 mb-2">
            <Settings className="h-6 w-6 text-blue-500" />
            <h1 className="text-3xl font-bold text-foreground">
              Project Settings
            </h1>
          </div>
          <div className="flex items-center gap-2 text-muted-foreground">
            <span>Project:</span>
            <Badge variant="outline" className="font-medium">
              E-commerce App Tests
            </Badge>
            <ChevronRight className="h-4 w-4" />
            <Badge variant="outline">Settings</Badge>
          </div>
        </div>

        {/* General Settings */}
        <Card className="mb-6">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Globe className="h-5 w-5" />
              General Settings
            </CardTitle>
            <CardDescription>
              Basic project configuration and details
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="projectName">Project Name</Label>
                <Input id="projectName" defaultValue="E-commerce App Tests" />
              </div>
              <div className="space-y-2">
                <Label htmlFor="projectId">Project ID</Label>
                <Input id="projectId" defaultValue="ECM-001" disabled />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Input
                id="description"
                defaultValue="End-to-end tests for the e-commerce mobile application"
              />
            </div>
          </CardContent>
        </Card>

        {/* GitHub Integration */}
        <Card className="mb-6">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Github className="h-5 w-5" />
              GitHub Integration
            </CardTitle>
            <CardDescription>
              Configure repository synchronization
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex items-center justify-between py-2">
              <div className="space-y-1">
                <p className="font-medium">Repository Connection</p>
                <p className="text-sm text-muted-foreground">
                  Enable GitHub repository sync
                </p>
              </div>
              <Switch />
            </div>
            <div className="space-y-2">
              <Label htmlFor="repo">Repository</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select repository" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="main">main/e-commerce-tests</SelectItem>
                  <SelectItem value="dev">dev/e-commerce-tests</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="branch">Default Branch</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select branch" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="main">main</SelectItem>
                  <SelectItem value="dev">development</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </CardContent>
        </Card>

        {/* Team & Permissions */}
        {/* <Card className="mb-6">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Users className="h-5 w-5" />
              Team & Permissions
            </CardTitle>
            <CardDescription>Manage team access and roles</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label>Project Owner</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select owner" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="john">John Doe</SelectItem>
                  <SelectItem value="jane">Jane Smith</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between mb-2">
                <Label>Team Members</Label>
                <Button variant="outline" size="sm">
                  Add Member
                </Button>
              </div>
              <Card className="bg-secondary/50">
                <CardContent className="p-4">
                  <div className="text-sm text-muted-foreground">
                    No team members added yet
                  </div>
                </CardContent>
              </Card>
            </div>
          </CardContent>
        </Card> */}

        {/* Notifications */}
        <Card className="mb-6">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Bell className="h-5 w-5" />
              Notifications
            </CardTitle>
            <CardDescription>Configure project notifications</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex items-center justify-between py-2">
              <div className="space-y-1">
                <p className="font-medium">Test Run Notifications</p>
                <p className="text-sm text-muted-foreground">
                  Notify when test runs complete
                </p>
              </div>
              <Switch />
            </div>
            <div className="flex items-center justify-between py-2">
              <div className="space-y-1">
                <p className="font-medium">Failure Alerts</p>
                <p className="text-sm text-muted-foreground">
                  Notify on test failures
                </p>
              </div>
              <Switch />
            </div>
          </CardContent>
        </Card>

        {/* Danger Zone */}
        <Card className="border-destructive/50">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-destructive">
              <Shield className="h-5 w-5" />
              Danger Zone
            </CardTitle>
            <CardDescription>Irreversible project actions</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Delete Project</p>
                <p className="text-sm text-muted-foreground">
                  Permanently remove this project and all its data
                </p>
              </div>
              <Button variant="destructive" className="gap-2">
                <Trash2 className="h-4 w-4" />
                Delete Project
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Save Button */}
        <div className="mt-8 flex justify-end gap-3">
          <Button variant="secondary">Cancel</Button>
          <Button className="gap-2">
            <Save className="h-4 w-4" />
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;
