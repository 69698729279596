import React from "react";
import {
  Home,
  ArrowLeft,
  Search,
  FileQuestion,
  HelpCircle
} from "lucide-react";
import { Button } from "@/components/ui/button";

const NotFound = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
      {/* Main Content */}
      <div className="text-center space-y-6 max-w-md">
        {/* Icon & Title */}
        <div className="space-y-4">
          <FileQuestion className="h-24 w-24 mx-auto text-blue-500 animate-pulse" />
          <h1 className="text-4xl font-bold text-foreground">Page Not Found</h1>
          <p className="text-muted-foreground text-lg">
            The page you're looking for doesn't exist or has been moved.
          </p>
        </div>

        {/* Common Links */}
        <div className="grid gap-4 pt-6">
          <Button
            variant="default"
            className="gap-2 w-full"
            onClick={() => window.history.back()}
          >
            <ArrowLeft className="h-4 w-4" />
            Go Back
          </Button>

          <Button
            variant="secondary"
            className="gap-2 w-full"
            onClick={() => (window.location.href = "/")}
          >
            <Home className="h-4 w-4" />
            Return Home
          </Button>
        </div>

        {/* Help Section */}
        <div className="pt-8 border-t border-border">
          <h2 className="text-sm font-medium text-foreground mb-4">
            Need Help?
          </h2>
          <div className="grid grid-cols-2 gap-3 text-sm">
            <Button variant="secondary" className="gap-2">
              <Search className="h-4 w-4" />
              Search Tests
            </Button>
            <Button variant="secondary" className="gap-2">
              <HelpCircle className="h-4 w-4" />
              Support
            </Button>
          </div>
        </div>

        {/* Footer */}
        <div className="pt-8 text-sm text-muted-foreground">
          Error Code: 404 | Page Not Found
        </div>
      </div>
    </div>
  );
};

export default NotFound;
