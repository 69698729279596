import React from "react";
import {
  PlayCircle,
  Plus,
  Search,
  Smartphone,
  Calendar,
  MoreVertical,
  CheckCircle2,
  Clock,
  Folder,
  XCircle,
  Settings
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from "@/components/ui/card";

const testPlans = [
  {
    id: 1,
    name: "Regression Test v1.0",
    status: "active",
    lastRun: "2024-12-10",
    device: {
      type: "emulator",
      farm: "Browserstack",
      model: "Samsung S24",
      os: "Android 14"
    },
    folders: ["/Authentication/Login Tests", "/Payment/Card Payment"],
    totalTests: 12,
    lastRunStatus: "passed"
  },
  {
    id: 2,
    name: "Smoke Test Suite",
    status: "scheduled",
    lastRun: "2024-12-09",
    device: {
      type: "real",
      farm: "Lambdatest",
      model: "iPhone 15",
      os: "iOS 17"
    },
    folders: ["/Authentication", "/Profile/Settings"],
    totalTests: 8,
    lastRunStatus: "failed"
  }
];

const TestPlanExplorer = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-6">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <PlayCircle className="h-6 w-6 text-blue-500" />
                <h1 className="text-3xl font-bold text-foreground">
                  Test Plans
                </h1>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <Badge variant="outline" className="font-medium">
                  {testPlans.length} Plans
                </Badge>
              </div>
            </div>
            <Button className="gap-2" size="lg">
              <Plus className="h-4 w-4" />
              New Test Plan
            </Button>
          </div>

          {/* Search and Filter */}
          <div className="flex gap-4">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input placeholder="Search test plans..." className="pl-10" />
            </div>
            <select className="w-40 rounded-md border bg-background px-3">
              <option value="all">All Plans</option>
              <option value="active">Active</option>
              <option value="scheduled">Scheduled</option>
            </select>
          </div>
        </div>

        {/* Test Plans Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {testPlans.map((plan) => (
            <Card
              key={plan.id}
              className="hover:border-blue-500/20 transition-colors"
            >
              <CardHeader>
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <CardTitle className="flex items-center gap-2">
                      {plan.name}
                      <Badge
                        variant={
                          plan.status === "active" ? "default" : "secondary"
                        }
                      >
                        {plan.status}
                      </Badge>
                    </CardTitle>
                    <CardDescription>Last run: {plan.lastRun}</CardDescription>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem>Edit Plan</DropdownMenuItem>
                      <DropdownMenuItem>Run Tests</DropdownMenuItem>
                      <DropdownMenuItem>View History</DropdownMenuItem>
                      <DropdownMenuItem className="text-destructive">
                        Delete Plan
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </CardHeader>
              <CardContent className="space-y-4">
                {/* Device Info */}
                <div className="flex items-start gap-3 text-sm">
                  <Smartphone className="h-4 w-4 mt-0.5 text-muted-foreground" />
                  <div>
                    <div className="font-medium">{plan.device.model}</div>
                    <div className="text-muted-foreground">
                      {plan.device.farm} • {plan.device.type} • {plan.device.os}
                    </div>
                  </div>
                </div>

                {/* Test Folders */}
                <div className="flex items-start gap-3 text-sm">
                  <Folder className="h-4 w-4 mt-0.5 text-muted-foreground" />
                  <div>
                    <div className="font-medium">{plan.totalTests} Tests</div>
                    <div className="text-muted-foreground line-clamp-2">
                      {plan.folders.join(", ")}
                    </div>
                  </div>
                </div>

                {/* Status & Actions */}
                <div className="flex items-center justify-between pt-4 border-t border-border">
                  <div className="flex items-center gap-2">
                    {plan.lastRunStatus === "passed" ? (
                      <CheckCircle2 className="h-4 w-4 text-green-500" />
                    ) : (
                      <XCircle className="h-4 w-4 text-red-500" />
                    )}
                    <span className="text-sm">
                      Last run: {plan.lastRunStatus}
                    </span>
                  </div>
                  <Button variant="secondary" size="sm" className="gap-2">
                    <PlayCircle className="h-4 w-4" />
                    Run Tests
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestPlanExplorer;
