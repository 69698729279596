import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

export const PermissionRoute = ({ children }) => {
  const { user, isLoading } = useAuth0();
  console.log("user", user);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Check permissions using drizz.dev namespace
  const hasPermission =
    user?.["https://drizz.dev/permissions"]?.includes("access:product") ||
    user?.app_metadata?.permissions?.includes("access:product");

  if (!hasPermission) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};
