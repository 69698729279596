import { ApiItem, GlobalVariable } from "@/types";

export const syncApisToStorage = (apis: ApiItem[]): void => {
  try {
    localStorage.setItem("savedApis", JSON.stringify(apis));
  } catch (error) {
    console.error("Failed to save APIs to localStorage:", error);
  }
};

export const loadApisFromStorage = (): ApiItem[] => {
  try {
    const savedApis = localStorage.getItem("savedApis");
    return savedApis ? JSON.parse(savedApis) : [];
  } catch (error) {
    console.error("Failed to load APIs from localStorage:", error);
    return [];
  }
};

export const loadVariablesFromStorage = (): GlobalVariable[] => {
  try {
    const stored = localStorage.getItem("globalVariables");
    return stored ? JSON.parse(stored) : [];
  } catch {
    return [];
  }
};

export const saveVariablesToStorage = (variables: GlobalVariable[]) => {
  localStorage.setItem("globalVariables", JSON.stringify(variables));
};
