import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./../store";
import { TestConfig } from "@/types";

interface EditorState {
  singleModeLoading: boolean;
  showEditor: boolean;
  isSingleLineMode: boolean;
  executedLines: number[];
  playerUrl: string;
  config: TestConfig;
  isLoading: boolean;
  instanceUUID: string | null;
}

const initialState: EditorState = {
  singleModeLoading: false,
  showEditor: false,
  isSingleLineMode: false,
  executedLines: [],
  playerUrl: "",
  config: {
    instanceName: "",
    appName: "",
    saveInstance: false
  },
  isLoading: false,
  instanceUUID: null
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setSingleModeLoading: (state, action: PayloadAction<boolean>) => {
      state.singleModeLoading = action.payload;
    },
    setShowEditor: (state, action: PayloadAction<boolean>) => {
      state.showEditor = action.payload;
    },
    setIsSingleLineMode: (state, action: PayloadAction<boolean>) => {
      state.isSingleLineMode = action.payload;
    },
    addExecutedLine: (state, action: PayloadAction<number>) => {
      if (!state.executedLines.includes(action.payload)) {
        state.executedLines.push(action.payload);
      }
    },
    clearExecutedLines: (state) => {
      state.executedLines = [];
    },
    setPlayerUrl: (state, action: PayloadAction<string>) => {
      state.playerUrl = action.payload;
    },
    setConfig: (state, action: PayloadAction<TestConfig>) => {
      state.config = action.payload;
    },

    updateSaveInstance: (state, action: PayloadAction<boolean>) => {
      state.config.saveInstance = action.payload;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInstanceUUID: (state, action: PayloadAction<string | null>) => {
      state.instanceUUID = action.payload;
    },
    resetEditor: (state) => {
      return initialState;
    }
  }
});

export const {
  setSingleModeLoading,
  setShowEditor,
  setIsSingleLineMode,
  addExecutedLine,
  clearExecutedLines,
  setPlayerUrl,
  setConfig,
  updateSaveInstance,
  setIsLoading,
  setInstanceUUID,
  resetEditor
} = editorSlice.actions;

// Selectors
export const selectSingleModeLoading = (state: RootState) =>
  state.editor.singleModeLoading;
export const selectShowEditor = (state: RootState) => state.editor.showEditor;
export const selectIsSingleLineMode = (state: RootState) =>
  state.editor.isSingleLineMode;
export const selectExecutedLines = (state: RootState) =>
  state.editor.executedLines;
export const selectPlayerUrl = (state: RootState) => state.editor.playerUrl;
export const selectConfig = (state: RootState) => state.editor.config;
export const selectIsLoading = (state: RootState) => state.editor.isLoading;
export const selectInstanceUUID = (state: RootState) =>
  state.editor.instanceUUID;

export default editorSlice.reducer;
