import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Upload, ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import {
  ApiItem,
  ApiFormData,
  ApiResponse,
  FormValues,
  formSchema
} from "@/types";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  addApi,
  executeApi,
  selectApis,
  updateApi
} from "@/store/features/apiSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiFormInputs } from "./ApiFormInputs";
import { ApiResponseViewer } from "./ApiResponse";
import { ApiStorage } from "@/store/s3/api";

interface ApiFormProps {
  initialData?: ApiItem;
  onSaved: () => void;
}

export function ApiForm({ initialData, onSaved }: ApiFormProps) {
  const dispatch = useAppDispatch();
  const existingApis = useAppSelector(selectApis);
  const apiStorage = new ApiStorage();

  const [formFilled, setFormFilled] = React.useState(false);
  const [apiResponse, setApiResponse] = React.useState<ApiResponse | null>(
    null
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { user, isLoading } = useAuth0();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: initialData?.name || "",
      method: initialData?.method || "GET",
      url: initialData?.url || "",
      headers: initialData?.headers || {},
      params: initialData?.params || {},
      body: initialData?.body || ""
    }
  });

  console.log("User Company", user.company_name);

  React.useEffect(() => {
    const subscription = form.watch((value) => {
      setFormFilled(Boolean(value.name && value.url));
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const fileContent = await file.text();
      const jsonData = JSON.parse(fileContent);

      if (!jsonData.method || !jsonData.url) {
        throw new Error(
          "Invalid JSON format: must contain method and url fields"
        );
      }

      const processedHeaders = Object.entries(jsonData.headers || {}).reduce(
        (acc, [headerKey, value]: [string, any]) => {
          acc[headerKey] = {
            ...value,
            id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
            key: headerKey
          };
          return acc;
        },
        {} as Record<string, any>
      );

      const bodyAsString = jsonData.data_schema
        ? JSON.stringify(jsonData.data_schema, null, 2)
        : "";

      form.reset({
        name: form.getValues("name"),
        method: jsonData.method,
        url: jsonData.url,
        headers: processedHeaders,
        params: jsonData.params || {},
        body: bodyAsString
      });

      setFormFilled(true);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error parsing JSON file:", error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  // const onSubmit = async (values: FormValues) => {
  //   try {
  //     // First check local state
  //     const isDuplicateLocal = existingApis.some(
  //       (api) => api.name === values.name && api.id !== initialData?.id
  //     );

  //     if (isDuplicateLocal) {
  //       form.setError("name", {
  //         type: "manual",
  //         message: "An API with this name already exists locally"
  //       });
  //       return;
  //     }

  //     // Then check S3 if it's a new API
  //     if (!initialData && user?.company_name) {
  //       const s3Apis = await apiStorage.getApisByCompany(user.company_name);
  //       const isDuplicateS3 = s3Apis.some((api) => api.name === values.name);

  //       if (isDuplicateS3) {
  //         form.setError("name", {
  //           type: "manual",
  //           message: "An API with this name already exists in storage"
  //         });
  //         return;
  //       }
  //     }

  //     const formData: ApiFormData = {
  //       name: values.name,
  //       method: values.method,
  //       url: values.url,
  //       headers: values.headers,
  //       params: values.params,
  //       body: values.body,
  //       responses: apiResponse
  //     };

  //     if (initialData) {
  //       // Update existing API
  //       await dispatch(updateApi({ id: initialData.id, data: formData }));
  //     } else {
  //       // Create new API
  //       const newApiItem: ApiItem = {
  //         ...formData,
  //         id: Date.now().toString(),
  //         created_at: new Date().toISOString(),
  //         updated_at: new Date().toISOString()
  //       };

  //       await dispatch(addApi(newApiItem));
  //       await apiStorage.storeApi(newApiItem, user.company_name);
  //     }
  //     onSaved();
  //   } catch (error) {
  //     console.error("Failed to save API:", error);
  //     form.setError("name", {
  //       type: "manual",
  //       message: "Error saving API. Please try again."
  //     });
  //   }
  // };

  const onSubmit = async (values: FormValues) => {
    try {
      // First check local state
      const isDuplicateLocal = existingApis.some(
        (api) => api.name === values.name && api.id !== initialData?.id
      );

      if (isDuplicateLocal) {
        form.setError("name", {
          type: "manual",
          message: "An API with this name already exists locally"
        });
        return;
      }

      // Then check S3 if it's a new API
      if (!initialData && user?.company_name) {
        const s3Apis = await apiStorage.getApisByCompany(user.company_name);
        const isDuplicateS3 = s3Apis.some((api) => api.name === values.name);

        if (isDuplicateS3) {
          form.setError("name", {
            type: "manual",
            message: "An API with this name already exists in storage"
          });
          return;
        }
      }

      const formData: ApiFormData = {
        name: values.name,
        method: values.method,
        url: values.url,
        headers: values.headers,
        params: values.params,
        body: values.body,
        responses: apiResponse
      };

      if (initialData) {
        // Update existing API
        const updatedApiItem: ApiItem = {
          ...initialData,
          ...formData,
          updated_at: new Date().toISOString()
        };

        await dispatch(updateApi({ id: initialData.id, data: formData }));

        if (user?.company_name) {
          await apiStorage.updateApi(
            initialData.id,
            updatedApiItem,
            user.company_name
          );
        }
      } else {
        // Create new API
        const newApiItem: ApiItem = {
          ...formData,
          id: Date.now().toString(),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };

        await dispatch(addApi(newApiItem));
        if (user?.company_name) {
          await apiStorage.storeApi(newApiItem, user.company_name);
        }
      }
      onSaved();
    } catch (error) {
      console.error("Failed to save API:", error);
      form.setError("name", {
        type: "manual",
        message: "Error saving API. Please try again."
      });
    }
  };

  const handleExecute = async () => {
    try {
      const values = form.getValues();

      const apiData: ApiItem = {
        id: initialData?.id || Date.now().toString(),
        name: values.name,
        method: values.method,
        url: values.url,
        headers: values.headers,
        params: values.params,
        body: values.body,
        created_at: initialData?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      const response = await executeApi(apiData);
      setApiResponse(response);
    } catch (error) {
      console.error("Failed to execute API:", error);
      setApiResponse({
        status: 500,
        statusText: "Error",
        headers: {},
        data: null,
        error: {
          message:
            error instanceof Error ? error.message : "Unknown error occurred"
        }
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <Button
          type="button"
          variant="outline"
          className="flex items-center gap-2 text-foreground"
          onClick={onSaved}
        >
          <ArrowLeft className="h-4 w-4" />
          Back
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
        />
        <Button
          type="button"
          variant="outline"
          onClick={handleUploadClick}
          size="sm"
        >
          <Upload className="h-4 w-4 mr-2" />
          Import JSON
        </Button>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <ApiFormInputs form={form} formFilled={formFilled} />

          <div className="flex justify-end gap-2">
            <Button type="submit" disabled={!formFilled}>
              {initialData ? "Update API" : "Save API"}
            </Button>
          </div>
        </form>
      </Form>

      <ApiResponseViewer
        response={apiResponse}
        apiName={form.getValues("name")}
        isNewApi={!initialData}
        onSave={form.handleSubmit(onSubmit)}
      />
    </div>
  );
}
