import { ScrollArea } from "@/components/ui/scroll-area";
import { Globe, Plus, Trash2, RefreshCcw } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ApiItem } from "@/types";
import {
  selectApis,
  selectExecutingApiId,
  deleteApi,
  setExecutingApiId,
  setApis
} from "@/store/features/apiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ApiStorage } from "@/store/s3/api";

interface ApiListProps {
  onSelect: (api: ApiItem | null) => void;
  onAddNew: () => void;
}

export function ApiList({ onSelect, onAddNew }: ApiListProps) {
  const dispatch = useAppDispatch();
  const apis = useAppSelector(selectApis);
  const executingId = useAppSelector(selectExecutingApiId);
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const apiStorage = new ApiStorage();

  const loadApisFromS3 = async () => {
    if (!user?.company_name) return;

    setIsLoading(true);
    try {
      const s3Apis = await apiStorage.getApisByCompany(user.company_name);
      dispatch(setApis(s3Apis));
    } catch (error) {
      console.error("Failed to load APIs from S3:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadApisFromS3();
  }, [user?.company_name]);

  const handleDelete = async (name: string) => {
    if (!user?.company_name) return;

    try {
      await apiStorage.deleteApi(name, user.company_name);
      dispatch(deleteApi(name));
    } catch (error) {
      console.error("Failed to delete API:", error);
    }
  };

  const handleExecute = async (api: ApiItem) => {
    dispatch(setExecutingApiId(api.id));
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      dispatch(setExecutingApiId(null));
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Button onClick={onAddNew} className="flex-1" variant="outline">
          <Plus className="h-4 w-4 mr-2" />
          Add New API
        </Button>
        <Button onClick={loadApisFromS3} variant="outline" disabled={isLoading}>
          <RefreshCcw
            className={`h-4 w-4 ${isLoading ? "animate-spin" : ""}`}
          />
        </Button>
      </div>

      {apis.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[400px] border-2 border-white border-dashed rounded-lg">
          <Globe className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium">No APIs configured</h3>
          <p className="text-sm text-muted-foreground mt-1">
            Get started by creating your first API configuration.
          </p>
        </div>
      ) : (
        <ScrollArea className="h-[calc(100vh-280px)]">
          <div className="space-y-4 pr-4">
            {apis.map((api) => (
              <ApiCard
                key={api.id}
                api={api}
                isExecuting={executingId === api.id}
                onSelect={() => onSelect(api)}
                onDelete={() => handleDelete(api.name)}
                onExecute={() => handleExecute(api)}
              />
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
}

// ApiCard component remains the same
interface ApiCardProps {
  api: ApiItem;
  isExecuting: boolean;
  onSelect: () => void;
  onDelete: () => void;
  onExecute: () => void;
}

function ApiCard({
  api,
  isExecuting,
  onSelect,
  onDelete,
  onExecute
}: ApiCardProps) {
  const getMethodColor = (method: string) => {
    const colors = {
      GET: "bg-emerald-500/10 text-emerald-500 hover:bg-emerald-500/20",
      POST: "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20",
      PUT: "bg-yellow-500/10 text-yellow-500 hover:bg-yellow-500/20",
      DELETE: "bg-red-500/10 text-red-500 hover:bg-red-500/20",
      PATCH: "bg-purple-500/10 text-purple-500 hover:bg-purple-500/20"
    } as const;

    return colors[method as keyof typeof colors] || colors.GET;
  };

  return (
    <Card
      className="transition-all border-gray-500 duration-200 cursor-pointer hover:shadow-md"
      onClick={onSelect}
    >
      <div className="p-4">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <Badge variant="secondary" className={getMethodColor(api.method)}>
              {api.method}
            </Badge>
            <h3 className="font-medium">{api.name}</h3>
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="text-destructive text-red-500 hover:text-destructive"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </Card>
  );
}
