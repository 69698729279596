import { useEffect, useState } from "react";
import * as monaco from "monaco-editor";
import { LineOperationsProps } from "@/types";

export const LineOperations: React.FC<LineOperationsProps> = ({
  lineNumber,
  onExecute,
  onMoveUp,
  onMoveDown,
  onClear,
  editor
}) => {
  const [position, setPosition] = useState({ top: 0 });

  useEffect(() => {
    if (!editor) return;
    const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
    const topOffset = editor.getTopForLineNumber(lineNumber);
    const editorScrollTop = editor.getScrollTop();

    setPosition({
      top: topOffset - editorScrollTop + (lineHeight - 32) / 2
    });

    const disposable = editor.onDidScrollChange(() => {
      const newTopOffset = editor.getTopForLineNumber(lineNumber);
      const newScrollTop = editor.getScrollTop();
      setPosition({
        top: newTopOffset - newScrollTop + (lineHeight - 32) / 2
      });
    });

    return () => disposable.dispose();
  }, [editor, lineNumber]);

  return (
    <div className="line-operations" style={position}>
      <button
        className="line-operation-button operation-play"
        onClick={() => onExecute(lineNumber)}
        title="Execute line"
      />
      <button
        className="line-operation-button operation-move-up"
        onClick={() => onMoveUp(lineNumber)}
        title="Move up"
      />
      <button
        className="line-operation-button operation-move-down"
        onClick={() => onMoveDown(lineNumber)}
        title="Move down"
      />
      <button
        className="line-operation-button operation-clear"
        onClick={() => onClear(lineNumber)}
        title="Clear line"
      />
    </div>
  );
};
