import { getJWTToken } from "@/services/jwtService";

export const constructPlayerUrl = async (
  emulatorId: string,
  webrtcUrl: string
): Promise<string> => {
  const jwtToken = await getJWTToken(emulatorId);
  return `https://drizz-b3b2f9ff-132b-4886-9f3a-da071d4.netlify.app?webrtcAddress=${encodeURIComponent(
    webrtcUrl
  )}&jwtToken=${encodeURIComponent(jwtToken)}`;
};
