import React from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { selectApis, selectGlobalVariables } from "@/store/features/apiSlice";
import { useAppSelector } from "@/store/hooks";
import { UseFormReturn } from "react-hook-form";
import { Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  FormField
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { formSchema, FormValues } from "@/types";
import * as z from "zod";
import { ApiStorage } from "@/store/s3/api";
import { useAuth0 } from "@auth0/auth0-react";

const VariableAwareInput = ({
  value,
  onChange,
  multiline = false,
  className = "",
  ...props
}) => {
  const variables = useAppSelector(selectGlobalVariables);
  const [cursorPosition, setCursorPosition] = React.useState(0);

  const replaceVariables = (text: string) => {
    let result = text;
    variables.forEach((variable) => {
      const pattern = new RegExp(`{{${variable.key}}}`, "g");
      result = result.replace(pattern, variable.value);
    });
    return result;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCursorPosition(e.target.selectionStart || 0);
    onChange(e);
  };

  const Component = multiline ? Textarea : Input;

  // Combine passed in className with your custom classes.
  const combinedClassName = `bg-black ${className}`.trim();

  return (
    <div className="relative">
      <Component
        {...props}
        value={value || ""}
        onChange={handleChange}
        className={combinedClassName}
        autoComplete="on"
      />
    </div>
  );
};

const defaultBody = `{
  "type": "object",
  "required": [
    "email"
  ],
  "properties": {
    "email": {
      "type": "string",
      "description": "The user's email address.",
      "example": "{{USER_API.contact.email}}"
    }
  }
}`;

export function ApiFormInputs({
  form,
  formFilled
}: {
  form: UseFormReturn<z.infer<typeof formSchema>>;
  formFilled: boolean;
}) {
  const existingApis = useAppSelector(selectApis);
  const { user } = useAuth0();
  const method = form.watch("method");
  const urlValue = form.watch("url");
  const headers = form.watch("headers") || {};
  const params = form.watch("params") || {};
  const apiStorage = new ApiStorage();

  const body = form.watch("body") || {
    type: "object",
    required: [],
    properties: {}
  };
  const showHeadersSection = formFilled || urlValue;

  const [headerKeys, setHeaderKeys] = React.useState<{ [key: string]: string }>(
    {}
  );
  const [paramKeys, setParamKeys] = React.useState<{ [key: string]: string }>(
    {}
  );

  const types = [
    { value: "string", label: "String" },
    { value: "integer", label: "Integer" },
    { value: "boolean", label: "Boolean" },
    { value: "array", label: "Array" },
    { value: "object", label: "Object" }
  ];

  const addHeader = () => {
    const tempId = Date.now().toString();
    const newHeaders = { ...headers };
    newHeaders[tempId] = {
      id: tempId,
      key: "",
      type: "string",
      description: "",
      example: "",
      default: ""
    };
    form.setValue("headers", newHeaders);
  };

  const removeHeader = (key: string) => {
    const newHeaders = { ...headers };
    delete newHeaders[key];
    setHeaderKeys((prev) => {
      const copy = { ...prev };
      delete copy[key];
      return copy;
    });
    form.setValue("headers", newHeaders);
  };

  const addParam = () => {
    const tempKey = Date.now().toString();
    const newParams = { ...params };
    newParams[tempKey] = {
      id: tempKey,
      key: "",
      type: "string",
      description: "",
      example: "",
      default: ""
    };
    setParamKeys((prev) => ({ ...prev, [tempKey]: "" }));
    form.setValue("params", newParams);
  };

  const removeParam = (key: string) => {
    const newParams = { ...params };
    delete newParams[key];
    setParamKeys((prev) => {
      const copy = { ...prev };
      delete copy[key];
      return copy;
    });
    form.setValue("params", newParams);
  };

  React.useEffect(() => {
    const initialHeaderKeys = Object.keys(headers).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {} as { [key: string]: string });
    setHeaderKeys(initialHeaderKeys);

    const initialParamKeys = Object.keys(params).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {} as { [key: string]: string });
    setParamKeys(initialParamKeys);
  }, [headers, params]);

  return (
    <div className="space-y-6">
      {/* API Name */}
      <FormField
        control={form.control}
        name="name"
        rules={{
          validate: (value) => {
            const nameExists = existingApis.some((api) => api.name === value);
            return !nameExists || "An API with this name already exists";
          }
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>API Name</FormLabel>
            <FormControl>
              <Input
                placeholder="enter_api_name"
                name="name"
                autoComplete="on"
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value;
                  const snakeCaseValue = value
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                    .replace(/[^a-z0-9_]/g, "");
                  field.onChange(snakeCaseValue);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Method & URL */}
      <div className="grid grid-cols-4 gap-4">
        <FormItem>
          <FormLabel>Method</FormLabel>
          <FormControl>
            <Select
              value={form.getValues("method")}
              onValueChange={(val) =>
                form.setValue("method", val as FormValues["method"])
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select method" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="GET">GET</SelectItem>
                <SelectItem value="POST">POST</SelectItem>
                <SelectItem value="PUT">PUT</SelectItem>
                <SelectItem value="PATCH">PATCH</SelectItem>
                <SelectItem value="DELETE">DELETE</SelectItem>
              </SelectContent>
            </Select>
          </FormControl>
        </FormItem>

        <FormItem className="col-span-3">
          <FormLabel>URL</FormLabel>
          <FormControl>
            <VariableAwareInput
              placeholder="https://api.example.com"
              name="url"
              value={form.getValues("url")}
              onChange={(e) => form.setValue("url", e.target.value)}
              autoComplete="on"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </div>

      {showHeadersSection && (
        <>
          {/* Parameters */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <FormLabel>Parameters</FormLabel>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addParam}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Parameter
              </Button>
            </div>

            <div className="space-y-4">
              {Object.entries(params).map(([key, param]) => (
                <Card key={key} className="p-3">
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        placeholder="Key"
                        name={`params.${key}.key`}
                        value={param.key}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(`params.${key}.key`, e.target.value);
                        }}
                        autoComplete="on"
                      />
                      <Input
                        placeholder="Value"
                        name={`params.${key}.default`}
                        value={param.default}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `params.${key}.default`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      />
                      {/* <Select
                        value={param.type}
                        onValueChange={(value) => {
                          form.setValue(`params.${key}.type`, value);
                        }}
                      >
                        <SelectTrigger className="text-sm">
                          <SelectValue placeholder="Type" />
                        </SelectTrigger>
                        <SelectContent>
                          {types.map((type) => (
                            <SelectItem key={type.value} value={type.value}>
                              {type.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select> */}
                    </div>

                    <Input
                      placeholder="Description"
                      name={`params.${key}.description`}
                      value={param.description}
                      className="text-sm"
                      onChange={(e) => {
                        form.setValue(
                          `params.${key}.description`,
                          e.target.value
                        );
                      }}
                      autoComplete="on"
                    />

                    <div className="grid grid-cols-2 gap-2">
                      {/* <Input
                        placeholder="Example"
                        name={`params.${key}.example`}
                        value={param.example || ""}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `params.${key}.example`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      /> */}
                    </div>

                    <div className="flex justify-end">
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeParam(key)}
                        className="h-6 w-6 p-0"
                      >
                        <Trash2 className="h-4 w-4 text-destructive" />
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>

          {/* Headers */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <FormLabel>Headers</FormLabel>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addHeader}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Header
              </Button>
            </div>

            <div className="space-y-4">
              {Object.entries(headers).map(([key, header]) => (
                <Card key={key} className="p-3">
                  <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        placeholder="Key"
                        name={`headers.${key}.key`}
                        value={header.key}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(`headers.${key}.key`, e.target.value);
                        }}
                        autoComplete="on"
                      />
                      <Input
                        placeholder="Value"
                        name={`headers.${key}.default`}
                        value={header.default}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `headers.${key}.default`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      />
                      {/* <Select
                        value={header.type}
                        onValueChange={(value) => {
                          form.setValue(`headers.${key}.type`, value);
                        }}
                      >
                        <SelectTrigger className="text-sm">
                          <SelectValue placeholder="Type" />
                        </SelectTrigger>
                        <SelectContent>
                          {types.map((type) => (
                            <SelectItem key={type.value} value={type.value}>
                              {type.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select> */}
                    </div>

                    <Input
                      placeholder="Description"
                      name={`headers.${key}.description`}
                      value={header.description}
                      className="text-sm"
                      onChange={(e) => {
                        form.setValue(
                          `headers.${key}.description`,
                          e.target.value
                        );
                      }}
                      autoComplete="on"
                    />

                    <div className="grid grid-cols-2 gap-2">
                      {/* <Input
                        placeholder="Example"
                        name={`headers.${key}.example`}
                        value={header.example || ""}
                        className="text-sm"
                        onChange={(e) => {
                          form.setValue(
                            `headers.${key}.example`,
                            e.target.value
                          );
                        }}
                        autoComplete="on"
                      /> */}
                    </div>

                    <div className="flex justify-end">
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeHeader(key)}
                        className="h-6 w-6 p-0"
                      >
                        <Trash2 className="h-4 w-4 text-destructive" />
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
          {(method === "POST" || method === "PUT" || method === "PATCH") && (
            <FormField
              control={form.control}
              name="body"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Request Body (JSON)</FormLabel>
                  <FormControl>
                    <VariableAwareInput
                      multiline={true}
                      placeholder={defaultBody}
                      className="font-mono min-h-[300px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </>
      )}
    </div>
  );
}
