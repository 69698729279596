import React, { useState, useRef, useEffect } from "react";
import { LoggerProps, ScriptStep, LogEntry } from "@/types";
import DOMPurify from "dompurify";
import "@/style/editor.css";

export const Logger: React.FC<LoggerProps> = ({ testScript, logs }) => {
  const [activeTab, setActiveTab] = useState<"testScript" | "testLogs">(
    "testLogs"
  );
  const logsEndRef = useRef<HTMLDivElement>(null);
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);

  useEffect(() => {
    if (autoScroll && logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs, autoScroll]);

  // Handle scroll events to detect if user has scrolled up
  const handleScroll = () => {
    if (!logsContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = logsContainerRef.current;
    const isScrolledToBottom = scrollHeight - scrollTop - clientHeight < 50; // 50px threshold

    setAutoScroll(isScrolledToBottom);
  };

  const renderTestScripts = (steps: ScriptStep[]) => {
    console.log("steps", steps);
    return steps.map((step) => (
      <div key={step.id} className="step-item">
        <div
          className={`step-icon ${step.status}`}
          data-id={step.id}
          title={`Status: ${step.status}`}
        />
        <span className="step-text">{step.text}</span>
      </div>
    ));
  };

  const renderLogs = (entries: LogEntry[]) => {
    if (entries.length === 0) {
      return (
        <div className="text-gray-500 italic p-4">Logs will appear here...</div>
      );
    }

    return (
      <>
        {entries.map((entry) => (
          <div
            key={entry.id}
            className={`log-entry ${
              entry.type === "error" ? "text-red-500" : "text-gray-200"
            }`}
          >
            <span className="log-timestamp">[{entry.timestamp}] </span>
            <span
              className="log-message"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(entry.message)
              }}
            />
          </div>
        ))}
        <div ref={logsEndRef} className="h-0" />
      </>
    );
  };

  // Show scroll to bottom button when not auto-scrolling
  const scrollToBottom = () => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
      setAutoScroll(true);
    }
  };

  return (
    <div className="logger-section">
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === "testScript" ? "active" : ""}`}
          onClick={() => setActiveTab("testScript")}
          type="button"
        >
          Test Status
        </button>
        <button
          className={`tab-button ${activeTab === "testLogs" ? "active" : ""}`}
          onClick={() => setActiveTab("testLogs")}
          type="button"
        >
          Log
        </button>
      </div>

      <div className="tab-panels">
        {activeTab === "testScript" ? (
          <div id="testScript" className="tab-content active">
            <div id="testScriptContent" className="test-script-panel">
              {renderTestScripts(testScript)}
            </div>
          </div>
        ) : (
          <div id="testLogs" className="tab-content active">
            <div
              ref={logsContainerRef}
              id="logs"
              className="log-content"
              onScroll={handleScroll}
            >
              {renderLogs(logs)}
            </div>

            {!autoScroll && logs.length > 0 && (
              <button
                onClick={scrollToBottom}
                className="scroll-to-bottom"
                aria-label="Scroll to bottom"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <polyline points="19 12 12 19 5 12" />
                </svg>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
