import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback
} from "react";
import * as monaco from "monaco-editor";
import { useAppSelector } from "@/store/hooks";
import { LineOperations } from "./LineOperations";
import {
  initializeMonaco,
  getEditorConfig,
  setupEditorShortcuts,
  updateEditorOptions
} from "@/utils/monaco-utils";
import { selectSingleModeLoading } from "@/store/features/editorSlice";
import "@/style/editor.css";
import "@/style/monaco-editor.css";
import { LineEditorProps, LineEditorRef } from "@/types";

export const LineMonacoEditor = forwardRef<LineEditorRef, LineEditorProps>(
  (
    {
      value,
      onChange,
      height = "500px",
      language = "plaintext",
      theme = "vs-dark",
      onLineExecute,
      executedLines = new Set(),
      onReorder
    },
    ref
  ) => {
    const singleModeLoading = useAppSelector(selectSingleModeLoading);
    console.log("singleModeLoading", singleModeLoading);
    const editorRef = useRef<HTMLDivElement>(null);
    const [editor, setEditor] =
      useState<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [executingLine, setExecutingLine] = useState<number | null>(null);
    const [localExecutedLines, setLocalExecutedLines] =
      useState<Set<number>>(executedLines);
    const [selectedLine, setSelectedLine] = useState<number | null>(null);
    const [activeLine, setActiveLine] = useState<number>(0);
    const [tooltip, setTooltip] = useState<{
      text: string;
      x: number;
      y: number;
    } | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    // Expose editor methods
    useImperativeHandle(ref, () => ({
      getValue: () => editor?.getValue() ?? "",
      setValue: (value: string) => editor?.setValue(value),
      editor: editor!,
      clearExecutedLines: () => {
        setLocalExecutedLines(new Set());
        if (editor) {
          editor.deltaDecorations([], []);
        }
      }
    }));

    // Initialize editor
    useEffect(() => {
      let mounted = true;

      const init = async () => {
        await initializeMonaco();

        if (!mounted || !editorRef.current || editor) return;

        const editorInstance = monaco.editor.create(editorRef.current, {
          ...getEditorConfig(value, language, theme, false, true),
          glyphMargin: false,
          lineDecorationsWidth: undefined,
          folding: false,
          readOnly: singleModeLoading
        });

        editorInstance.onDidChangeModelContent(() => {
          onChange(editorInstance.getValue());
        });

        setupEditorShortcuts(editorInstance);
        setEditor(editorInstance);
      };

      init();

      return () => {
        mounted = false;
        if (editor) {
          editor.dispose();
        }
      };
    }, []);

    // Sync executed lines with props
    useEffect(() => {
      setLocalExecutedLines(executedLines);
    }, [executedLines]);

    // Update editor options when running state changes
    useEffect(() => {
      if (editor) {
        updateEditorOptions(editor, singleModeLoading);
      }
    }, [singleModeLoading, editor]);

    // Update editor value
    useEffect(() => {
      if (editor && value !== editor.getValue()) {
        editor.setValue(value);
      }
    }, [value]);

    // Track active line
    useEffect(() => {
      if (!editor) return;

      const updateActiveLine = () => {
        const position = editor.getPosition();
        if (position) {
          setActiveLine(position.lineNumber);
        }
      };

      const disposables = [
        editor.onDidChangeCursorPosition(() => {
          updateActiveLine();
        })
      ];

      updateActiveLine();

      return () => disposables.forEach((d) => d.dispose());
    }, [editor]);

    // Line operations handlers
    const handleLineSelect = useCallback(
      (e: monaco.editor.IEditorMouseEvent) => {
        if (e.target.position) {
          setSelectedLine(e.target.position.lineNumber);
        }
      },
      []
    );

    const handleLineExecution = async (lineNumber: number) => {
      if (!editor) return;
      const content = editor.getModel()?.getLineContent(lineNumber)?.trim();
      if (!content) return;

      setExecutingLine(lineNumber);
      try {
        await onLineExecute?.(lineNumber, content);
        setLocalExecutedLines((prev) => new Set([...prev, lineNumber]));
      } finally {
        setExecutingLine(null);
      }
    };

    const handleMoveUp = useCallback(
      (lineNumber: number) => {
        if (!onReorder || lineNumber <= 1) return;
        onReorder(lineNumber - 1, lineNumber - 2);
      },
      [onReorder]
    );

    const handleMoveDown = useCallback(
      (lineNumber: number) => {
        if (!onReorder || !editor) return;
        const totalLines = editor.getModel()?.getLineCount() || 0;
        if (lineNumber < totalLines) {
          onReorder(lineNumber - 1, lineNumber);
        }
      },
      [onReorder, editor]
    );

    const handleClearLine = useCallback(
      (lineNumber: number) => {
        if (!editor) return;
        const model = editor.getModel();
        if (model) {
          editor.executeEdits("", [
            {
              range: new monaco.Range(
                lineNumber,
                1,
                lineNumber,
                model.getLineMaxColumn(lineNumber)
              ),
              text: ""
            }
          ]);
        }
      },
      [editor]
    );

    // Update editor decorations
    useEffect(() => {
      if (!editor) return;

      const model = editor.getModel();
      if (!model) return;

      const decorations = [];
      const lineCount = model.getLineCount();

      for (let i = 1; i <= lineCount; i++) {
        const lineContent = model.getLineContent(i).trim();
        if (lineContent) {
          const isExecuting = executingLine === i;
          const isExecuted = localExecutedLines.has(i);
          const isSelected = selectedLine === i;

          decorations.push({
            range: new monaco.Range(i, 1, i, 1),
            options: {
              isWholeLine: true,
              linesDecorationsClassName: `line-execution-margin ${
                isExecuting ? "executing" : ""
              } ${isExecuted ? "executed" : ""} ${
                isSelected ? "selected" : ""
              }`,
              className: `line-background ${isSelected ? "selected-line" : ""}`,
              glyphMarginClassName: "line-execution-glyph",
              stickiness:
                monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges
            }
          });
        }
      }

      editor.deltaDecorations([], decorations);
    }, [editor, value, executingLine, localExecutedLines, selectedLine]);

    // Handle line selection and cursor movement
    useEffect(() => {
      if (!editor) return;

      const disposables = [
        editor.onDidChangeCursorPosition((e) => {
          setSelectedLine(e.position.lineNumber);
        }),

        editor.onMouseMove(handleLineSelect),

        editor.onDidBlurEditorText(() => {
          // Optionally clear selection when editor loses focus
          // setSelectedLine(null);
        })
      ];

      return () => {
        disposables.forEach((d) => d.dispose());
      };
    }, [editor, handleLineSelect]);

    // Handle tooltip
    useEffect(() => {
      if (!editor) return;

      const disposable = editor.onMouseMove((e) => {
        if (
          e.target.type === monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN
        ) {
          const { lineNumber } = e.target.position || {};
          if (lineNumber) {
            const lineContent = editor
              .getModel()
              ?.getLineContent(lineNumber)
              ?.trim();
            if (lineContent) {
              setTooltip({
                text: localExecutedLines.has(lineNumber)
                  ? "Line executed"
                  : `Execute: ${lineContent}`,
                x: e.event.posx + 10,
                y: e.event.posy + 10
              });
            }
          }
        } else {
          setTooltip(null);
        }
      });

      return () => disposable.dispose();
    }, [editor, localExecutedLines]);

    // Handle keyboard shortcuts
    useEffect(() => {
      if (!editor) return;

      const handleKeyboard = (e: KeyboardEvent) => {
        if (!selectedLine) return;

        // Execute current line with Shift + Enter
        if (e.key === "Enter" && e.shiftKey) {
          e.preventDefault();
          handleLineExecution(selectedLine);
        }

        // Move line up with Alt + ArrowUp
        if (e.key === "ArrowUp" && e.altKey) {
          e.preventDefault();
          handleMoveUp(selectedLine);
        }

        // Move line down with Alt + ArrowDown
        if (e.key === "ArrowDown" && e.altKey) {
          e.preventDefault();
          handleMoveDown(selectedLine);
        }
      };

      editor.getDomNode()?.addEventListener("keydown", handleKeyboard);

      return () => {
        editor.getDomNode()?.removeEventListener("keydown", handleKeyboard);
      };
    }, [editor, selectedLine, handleMoveUp, handleMoveDown]);

    return (
      <>
        <div
          ref={editorRef}
          className={`monaco-editor-container single-line-mode ${
            isDragging ? "dragging" : ""
          } ${theme}`}
          style={{
            height,
            width: "100%",
            overflow: "hidden",
            border: "1px solid #ccc",
            borderRadius: "4px"
          }}
        />
        {selectedLine !== null && (
          <LineOperations
            lineNumber={activeLine}
            onExecute={handleLineExecution}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onClear={handleClearLine}
            isExecuted={localExecutedLines.has(activeLine)}
            editor={editor}
          />
        )}
        {tooltip && (
          <div
            className="execution-tooltip"
            style={{
              left: tooltip.x,
              top: tooltip.y
            }}
          >
            {tooltip.text}
          </div>
        )}
      </>
    );
  }
);

LineMonacoEditor.displayName = "LineMonacoEditor";
