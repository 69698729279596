import { useAuth0 } from "@auth0/auth0-react";
import { LogOut, Terminal, AlertCircle } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const UnauthorizedPage = () => {
  const { user, logout } = useAuth0();

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        {/* Logo */}
        <div className="text-center flex items-center justify-center gap-2">
          <Terminal className="h-8 w-8 text-blue-500" />
          <div className="flex flex-col items-start">
            <h1 className="text-4xl font-bold text-white">Drizz</h1>
            <span className="text-blue-500 text-sm font-medium -mt-1">
              Playground
            </span>
          </div>
        </div>

        <Card className="bg-gray-900/50 border-gray-800">
          <CardContent className="pt-6 space-y-6">
            <div className="text-center space-y-4">
              <AlertCircle className="h-12 w-12 text-yellow-500 mx-auto" />
              <h2 className="text-2xl font-semibold text-white">
                Access Pending
              </h2>
            </div>

            <Alert className="bg-yellow-950/30 border-yellow-900/50">
              <AlertDescription className="text-yellow-400">
                Your account ({user?.email}) is currently not authorized to
                access this application. Please contact your administrator to
                request access.
              </AlertDescription>
            </Alert>

            <Button
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              className="w-full bg-blue-500/10 border-blue-500/20 text-blue-400 
                hover:bg-blue-500 hover:text-white hover:border-blue-500 
                hover:shadow-lg hover:shadow-blue-500/25"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Sign Out
            </Button>

            <p className="text-center text-sm text-gray-500">
              For assistance, please email:{" "}
              <a
                href="mailto:support@drizz.dev"
                className="text-blue-400 hover:underline"
              >
                support@drizz.dev
              </a>
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
