import {
  ArrowLeft,
  Settings,
  PlayCircle,
  Save,
  FileText,
  Folder
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const CreateTestScreen = ({
  currentPath = "/Authentication/Login Tests",
  onBack
}) => {
  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header */}
        <div className="flex items-center gap-4 mb-8">
          <Button variant="secondary" size="icon" onClick={onBack}>
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div className="flex-1">
            <h1 className="text-3xl font-bold text-foreground mb-2">
              Create New
            </h1>
            <div className="flex items-center gap-2 text-muted-foreground">
              <span>Location:</span>
              <Badge variant="outline" className="font-medium">
                {currentPath}
              </Badge>
            </div>
          </div>
          <Button className="gap-2">
            <Save className="h-4 w-4" />
            Save
          </Button>
        </div>

        {/* Type Selection */}
        <Tabs defaultValue="test" className="w-full">
          <TabsList className="w-full max-w-md mx-auto mb-6">
            <TabsTrigger value="test" className="flex-1">
              <div className="flex items-center justify-center gap-2 py-2">
                <FileText className="h-5 w-5" />
                <span>Test Case</span>
              </div>
            </TabsTrigger>
            <TabsTrigger value="folder" className="flex-1">
              <div className="flex items-center justify-center gap-2 py-2">
                <Folder className="h-5 w-5" />
                <span>Folder</span>
              </div>
            </TabsTrigger>
          </TabsList>

          {/* Folder Creation */}
          <TabsContent value="folder">
            <Card>
              <CardHeader>
                <CardTitle>Folder Details</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="folderName">Folder Name</Label>
                  <Input id="folderName" placeholder="Enter folder name" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="folderDesc">Description (Optional)</Label>
                  <Textarea
                    id="folderDesc"
                    placeholder="Brief description about this folder"
                    className="resize-none"
                    rows={3}
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          {/* Test Case Creation */}
          <TabsContent value="test">
            <div className="grid gap-6">
              {/* Basic Info */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <FileText className="h-5 w-5" />
                    Basic Information
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="testName">Test Case Name</Label>
                    <Input id="testName" placeholder="Enter test case name" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="testDesc">Description</Label>
                    <Textarea
                      id="testDesc"
                      placeholder="Describe the purpose of this test case"
                      className="resize-none"
                      rows={3}
                    />
                  </div>
                </CardContent>
              </Card>

              {/* Prerequisites */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Settings className="h-5 w-5" />
                    Prerequisites
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <h3 className="font-medium">Device Requirements</h3>
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <Label>Platform</Label>
                          <select className="w-full h-10 rounded-md border bg-background px-3">
                            <option>iOS</option>
                            <option>Android</option>
                          </select>
                        </div>
                        <div className="space-y-2">
                          <Label>Minimum OS Version</Label>
                          <Input placeholder="e.g., 12.0" />
                        </div>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <h3 className="font-medium">App Permissions</h3>
                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="location"
                            className="rounded border-gray-400"
                          />
                          <Label htmlFor="location" className="text-sm">
                            Location Access
                          </Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="notifications"
                            className="rounded border-gray-400"
                          />
                          <Label htmlFor="notifications" className="text-sm">
                            Notifications
                          </Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="storage"
                            className="rounded border-gray-400"
                          />
                          <Label htmlFor="storage" className="text-sm">
                            Storage Access
                          </Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="camera"
                            className="rounded border-gray-400"
                          />
                          <Label htmlFor="camera" className="text-sm">
                            Camera Access
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Test Steps */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <PlayCircle className="h-5 w-5" />
                    Test Steps
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="space-y-2">
                    <Label>Pre-conditions</Label>
                    <Textarea
                      placeholder="Steps to execute before launching the app"
                      className="resize-none"
                      rows={2}
                    />
                  </div>
                  {/* <div className="space-y-2">
                    <Label>Test Steps</Label>
                    <Textarea
                      placeholder="Main test case steps"
                      className="resize-none"
                      rows={4}
                    />
                  </div> */}
                  <div className="space-y-2">
                    <Label>Post-conditions</Label>
                    <Textarea
                      placeholder="Steps to execute after test completion"
                      className="resize-none"
                      rows={2}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default CreateTestScreen;
