import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Loader2, Mail, Terminal } from "lucide-react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { loginWithRedirect } = useAuth0();

  const handleMagicLink = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email");
      return;
    }

    setLoading(true);
    setError("");

    try {
      await loginWithRedirect({
        authorizationParams: {
          connection: "email",
          login_hint: email,
          scope: "openid profile email",
          response_type: "token id_token",
          passwordless_method: "link"
        }
      });
    } catch (err) {
      console.error("Login error:", err);
      setError("Failed to send magic link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        connection: "google-oauth2",
        scope: "openid profile email"
      }
    });
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center flex items-center justify-center gap-2">
          <Terminal className="h-8 w-8 text-blue-500" />
          <div className="flex flex-col items-start">
            <h1 className="text-4xl font-bold text-white">Drizz</h1>
            <span className="text-blue-500 text-sm font-medium -mt-1">
              Playground
            </span>
          </div>
        </div>

        <Card className="bg-gray-900/50 border-gray-800">
          <CardHeader>
            <h2 className="text-2xl font-semibold text-white">Welcome Back</h2>
          </CardHeader>
          <CardContent className="space-y-6">
            {error && (
              <Alert
                variant="destructive"
                className="bg-red-950/50 border-red-900/50"
              >
                <AlertDescription className="text-red-400">
                  {error}
                </AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleMagicLink} className="space-y-5">
              <div className="space-y-2">
                <Label htmlFor="email" className="text-gray-300">
                  Email Address
                </Label>
                <div className="relative">
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@company.com"
                    className="pl-10 bg-gray-900/50 border-gray-800 text-white placeholder:text-gray-500"
                    required
                  />
                  <Mail className="w-5 h-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>

              <Button
                type="submit"
                disabled={loading}
                className="w-full bg-blue-500/10 border-blue-500/20 text-blue-400 hover:bg-blue-500 hover:text-white"
              >
                {loading && <Loader2 className="w-5 h-5 mr-2 animate-spin" />}
                {loading ? "Sending Magic Link..." : "Continue with Email"}
              </Button>
            </form>

            {loading && (
              <Alert className="bg-blue-950/50 border-blue-900/50">
                <AlertDescription className="text-blue-400">
                  <h3 className="font-medium mb-2">Check your email</h3>
                  <ul className="space-y-1 ml-6 list-disc">
                    <li>Magic link has been sent to {email}</li>
                    <li>Check spam/junk folder if not found</li>
                    <li>Add noreply@drizz.dev to contacts</li>
                  </ul>
                </AlertDescription>
              </Alert>
            )}

            <div className="relative">
              <Separator className="bg-gray-800" />
              <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-900 px-2 text-gray-500 text-sm">
                or continue with
              </span>
            </div>

            <Button
              onClick={handleGoogleLogin}
              variant="outline"
              className="w-full bg-white hover:bg-gray-50 text-gray-900 border border-gray-300 hover:border-gray-400 transition-all duration-200"
            >
              <svg className="w-5 h-5 mr-3" viewBox="0 0 48 48">
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                />
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                />
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                />
              </svg>
              Continue with Google
            </Button>
          </CardContent>
        </Card>

        <Card className="bg-gray-900/50 border-gray-800">
          <CardContent className="pt-6">
            <h3 className="text-sm font-medium text-gray-300 mb-2">
              Having trouble receiving the magic link?
            </h3>
            <ul className="text-sm text-gray-400 space-y-2">
              <li className="flex items-start">
                <span className="mr-2">1.</span>
                Check your spam/junk folder
              </li>
              <li className="flex items-start">
                <span className="mr-2">2.</span>
                Mark our emails as "Not Spam" if found in spam folder
              </li>
            </ul>
          </CardContent>
        </Card>

        <p className="text-center text-sm text-gray-500">
          By continuing, you agree to drizz.dev's{" "}
          <a href="#" className="text-blue-400 hover:underline">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="#" className="text-blue-400 hover:underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
