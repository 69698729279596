import * as monaco from "monaco-editor";

const baseConfig: monaco.editor.IStandaloneEditorConstructionOptions = {
  automaticLayout: true,
  scrollBeyondLastLine: false,
  lineNumbers: "on",
  roundedSelection: true,
  scrollbar: {
    vertical: "visible",
    horizontal: "visible",
    useShadows: false,
    verticalScrollbarSize: 10,
    horizontalScrollbarSize: 10
  },
  overviewRulerLanes: 0,
  fontSize: 14,
  lineHeight: 21,
  padding: { top: 10, bottom: 10 },
  contextmenu: false,
  wordWrap: "on"
};

// Jupyter-like configuration for single line mode
const singleLineModeConfig: monaco.editor.IStandaloneEditorConstructionOptions =
  {
    ...baseConfig,
    glyphMargin: true,
    lineNumbers: "on",
    lineDecorationsWidth: 40,
    lineNumbersMinChars: 3,
    folding: false,
    minimap: { enabled: false },
    renderLineHighlight: "none",
    autoClosingBrackets: "always",
    autoClosingQuotes: "always",
    autoSurround: "quotes",
    formatOnPaste: true,
    formatOnType: true,
    suggestOnTriggerCharacters: true,
    acceptSuggestionOnCommitCharacter: true,
    parameterHints: { enabled: true },
    hover: {
      enabled: true,
      delay: 100,
      sticky: true
    },
    fontFamily:
      "'JetBrains Mono', 'Fira Code', Monaco, 'Courier New', monospace",
    fontSize: 14,
    lineHeight: 24,
    padding: { top: 12, bottom: 12 }
  };

// Default mode configuration
// const defaultModeConfig: monaco.editor.IStandaloneEditorConstructionOptions = {
//   ...baseConfig,
//   glyphMargin: false,
//   folding: true,
//   minimap: { enabled: false },
//   renderLineHighlight: "line",
//   autoClosingBrackets: "never",
//   autoClosingQuotes: "never",
//   autoSurround: "never",
//   formatOnPaste: false,
//   formatOnType: false,
//   suggestOnTriggerCharacters: false,
//   parameterHints: { enabled: false }
// };

const defaultModeConfig: monaco.editor.IStandaloneEditorConstructionOptions = {
  ...baseConfig,
  glyphMargin: false,
  folding: true,
  minimap: { enabled: false },
  renderLineHighlight: "line",
  quickSuggestions: { other: true, comments: true, strings: true },
  suggestOnTriggerCharacters: true,
  parameterHints: { enabled: true },
  acceptSuggestionOnEnter: "on", // This ensures Enter key commits the suggestion
  suggest: {
    insertMode: "insert",
    snippetsPreventQuickSuggestions: false,
    showIcons: true
  }
};

// Get editor configuration based on mode
export const getEditorConfig = (
  value: string,
  language: string,
  theme: string,
  isRunning: boolean,
  isSingleLineMode: boolean
): monaco.editor.IStandaloneEditorConstructionOptions => ({
  ...(isSingleLineMode ? singleLineModeConfig : defaultModeConfig),
  value,
  language,
  theme,
  readOnly: isRunning,
  quickSuggestions: !isRunning
});

// Initialize Monaco
export const initializeMonaco = async (): Promise<void> => {
  if ((window as any).monaco) return;

  return new Promise<void>((resolve) => {
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.36.1/min/vs/loader.min.js";
    script.onload = () => {
      (window as any).require.config({
        paths: {
          vs: "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.36.1/min/vs"
        }
      });
      (window as any).require(["vs/editor/editor.main"], () => {
        resolve();
      });
    };
    document.body.appendChild(script);
  });
};

// Setup keyboard shortcuts
export const setupEditorShortcuts = (
  editor: monaco.editor.IStandaloneCodeEditor
): void => {
  // Undo/Redo
  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyZ, () => {
    editor.trigger("keyboard", "undo", null);
  });

  editor.addCommand(
    monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyZ,
    () => {
      editor.trigger("keyboard", "redo", null);
    }
  );

  // Basic text input
  editor.addCommand(monaco.KeyCode.Space, () => {
    editor.trigger("keyboard", "type", { text: " " });
  });

  editor.addCommand(monaco.KeyCode.Enter, () => {
    editor.trigger("keyboard", "type", { text: "\n" });
  });
};

// Update editor options based on running state
export const updateEditorOptions = (
  editor: monaco.editor.IStandaloneCodeEditor,
  isRunning: boolean
): void => {
  editor.updateOptions({
    readOnly: isRunning,
    quickSuggestions: !isRunning,
    suggestOnTriggerCharacters: !isRunning,
    parameterHints: { enabled: !isRunning },
    acceptSuggestionOnCommitCharacter: !isRunning
  });
};
