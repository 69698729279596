import React, { useState } from "react";
import {
  ChevronRight,
  ChevronDown,
  Folder,
  FolderPlus,
  FilePlus,
  File,
  FileText,
  MoreVertical,
  Plus,
  Search,
  Code,
  ListFilter,
  Settings,
  PlayCircle,
  Smartphone
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const initialFiles = {
  id: "root",
  name: "Test Cases",
  type: "folder",
  children: [
    {
      id: "1",
      name: "Authentication",
      type: "folder",
      children: [
        {
          id: "1-1",
          name: "Login Tests",
          type: "folder",
          children: [
            { id: "1-1-1", name: "valid_login.test", type: "file" },
            { id: "1-1-2", name: "invalid_credentials.test", type: "file" }
          ]
        },
        { id: "1-2", name: "signup_flow.test", type: "file" }
      ]
    },
    {
      id: "2",
      name: "Payment",
      type: "folder",
      children: [
        { id: "2-1", name: "card_payment.test", type: "file" },
        { id: "2-2", name: "refund_process.test", type: "file" }
      ]
    }
  ]
};

// File Tree Node Component
const FileTreeNode = ({ node, level = 0, onCreateNew }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <div
        className={`group relative flex items-center py-2.5 px-4 hover:bg-secondary/50 rounded-md cursor-pointer ${
          isHovered ? "bg-secondary/30" : ""
        }`}
        style={{ paddingLeft: `${level * 20}px` }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex items-center flex-1 gap-2">
          {node.type === "folder" ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className="p-1 hover:bg-secondary rounded-md"
            >
              {isOpen ? (
                <ChevronDown className="h-4 w-4 text-foreground transition-transform" />
              ) : (
                <ChevronRight className="h-4 w-4 text-foreground transition-transform" />
              )}
            </button>
          ) : (
            <div className="w-6" />
          )}

          {node.type === "folder" ? (
            <Folder className="h-4 w-4 text-blue-500" />
          ) : (
            <File className="h-4 w-4 text-blue-400" />
          )}
          <span className="text-sm text-foreground font-medium">
            {node.name}
          </span>
          {node.type === "folder" && node.children?.length > 0 && (
            <Badge variant="secondary" className="ml-2 h-5 px-2 py-0">
              {node.children.length}
            </Badge>
          )}
        </div>

        <div
          className={`absolute right-4 flex items-center gap-1 bg-background/95 px-2 py-1 rounded-md transition-opacity duration-200 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          {node.type === "folder" && (
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-7 w-7 hover:bg-secondary"
                title="New Folder"
                onClick={() => onCreateNew(node, "folder")}
              >
                <FolderPlus className="h-4 w-4 text-foreground" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-7 w-7 hover:bg-secondary"
                title="New File"
                onClick={() => onCreateNew(node, "test")}
              >
                <FilePlus className="h-4 w-4 text-foreground" />
              </Button>
            </>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="h-7 w-7 hover:bg-secondary"
              >
                <MoreVertical className="h-4 w-4 text-foreground" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Rename</DropdownMenuItem>
              <DropdownMenuItem>Move</DropdownMenuItem>
              <DropdownMenuItem className="text-destructive">
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {node.type === "folder" && isOpen && node.children && (
        <div className="pl-4">
          {node.children.map((child) => (
            <FileTreeNode
              key={child.id}
              node={child}
              level={level + 1}
              onCreateNew={onCreateNew}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Main Component
const TestFileExplorer = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [createType, setCreateType] = useState("test");

  const handleCreateNew = (folder, type) => {
    setSelectedFolder(folder);
    setCreateType(type);
    setIsCreateModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="p-6 max-w-6xl mx-auto pt-20">
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-6">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <Code className="h-6 w-6 text-blue-500" />
                <h1 className="text-3xl font-bold text-foreground">
                  Test Explorer
                </h1>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <span>Project:</span>
                <Badge variant="outline" className="font-medium">
                  E-commerce App Tests
                </Badge>
                <span className="text-muted-foreground">•</span>
                <Badge variant="outline" className="font-medium">
                  24 Test Cases
                </Badge>
              </div>
            </div>
            <div className="flex gap-2">
              <Button variant="secondary" className="gap-2">
                <ListFilter className="h-4 w-4" />
                Filter
              </Button>
              <Button
                className="gap-2"
                onClick={() => handleCreateNew(initialFiles, "test")}
              >
                <Plus className="h-4 w-4" />
                New Test Case
              </Button>
            </div>
          </div>

          {/* Search Bar */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input placeholder="Search test cases..." className="pl-10" />
          </div>
        </div>

        {/* File Tree */}
        <div className="rounded-lg bg-card">
          <FileTreeNode node={initialFiles} onCreateNew={handleCreateNew} />
        </div>
      </div>
    </div>
  );
};

export default TestFileExplorer;
